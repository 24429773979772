const AWS = require('aws-sdk');

AWS.config.update({
    accessKeyId: 'AKIAR3PSH3N4O5RINPW7',
    secretAccessKey: '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',
    region: 'ap-south-1'
});

// const s3 = new AWS.S3();
const s3 = new AWS.S3({
    accessKeyId: 'AKIAR3PSH3N4O5RINPW7',
    secretAccessKey: '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',
    region: 'ap-south-1'
});
export const getExternalConfigData = async (filename) => {
    var getParams = {
        Bucket: 'lm-webquiz', // your bucket name,
        Key: `images/${filename}` + ".png"// path to the object you're looking for
    }
    try {
        const data = await s3.getObject(getParams).promise();
        var contents = JSON.parse(data.Body.toString('utf-8'));
        return contents;
    } catch (err) {
        console.log(err);
    }
}


export const getImage = (fileName) => {
    s3.getObject({ Bucket: 'lm-webquiz', Key: `images/${fileName}` }, function (err, data) {
        if (err) {
            console.log(err);
        } else {
            const url = URL.createObjectURL(new Blob([data.Body]));
            return url
            // Set the URL as the source of an <img> element
        }
    });
}