import axios from "axios";
import { createContext } from "react";

export const inititalState = {
    userName: "",
    userEmail: "",
    schoolDetails: [],
    users_list: [],
    selected_School: {
        school_name: "",
        accnt_id: "",
        grades: "",
        students: "",
        batches: ""
    },
    school_id: "Dummy id"
};

export const UserContext = createContext()
export const userReducer = (state = inititalState, action) => {

    console.log(action)
    switch (action.type) {


        case "Set_UserName":
            console.log("Set_UserName", action.payload);
            return {
                ...state,
                userName: action.payload.userName,
                userEmail: action.payload.userEmail
            }
        case "Get_Data":
            console.log("Getting data from reducer", action.payload);
            return state
        case "addSlctdSchlId":
            console.log("Adding selecting school id", action);
            return {
                ...state,
                selected_School: {
                    school_name: action.payload.school_name,
                    accnt_id: action.payload.accnt_id,
                    grades: action.payload.grades,
                    students: action.payload.students,
                    batches: action.payload.batches
                }
            };

        case "Update_Data":
            console.log("Updating data from reducer");
            return action.payload
    }
}


export const inititalSchoolState = {};

export const schoolReducer = (state = inititalState, action) => {
    if (localStorage.getItem("user_email") != "") {
        console.log("ssssssssssssssss", localStorage.getItem("userEmail"));
    }
    switch (action.type) {
        case "Get_Schools":
            console.log("Getting data from reducer", action.payload);
            return state
        case "Get_School_Users":
            console.log("Updating data from reducer");
            return action.payload
    }
}
