import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { basic } from '../../../themes/basic';

const LoadingOverlay = ({ loading }) => {
  if (!loading) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300, // Ensure the loading spinner is above other elements
      }}
    >
      <CircularProgress style={{ color: basic.greenColor }} size={54} />
    </Box>
  );
};

export default LoadingOverlay;