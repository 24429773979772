import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import { getAllQuestionBankSets, gets3Image } from "../../../Services/api";
import Dropdown from 'react-bootstrap/Dropdown';
import { getExternalConfigData, getImage } from "../../../configs/aws-config";

const GenerateQuiz = (props) => {
    const [shuffledQuiz, setShuffledQuiz] = useState([])
    const [allQuestions, setAllQuestions] = useState([])
    const [answerOptions, setAnswerOptions] = useState(["a", "b", "c", "d"])
    const [showQB, setShowQB] = useState("QB Set 1 ")
    const [allQBSets, setAllQBSets] = useState([])
    const [slctdQBSet, setSlctdQbSet] = useState([])
    const [selectedQBName, setSelectedQBName] = useState('Select QB Set');


    useEffect(() => {
        getAllQBSets()
    }, [])

    useEffect(() => {
        let temp = []
        if (slctdQBSet.length > 0) {
            slctdQBSet.map((dt, k) => {
                console.log("dt.mediaType", dt.mediaType);
                if (dt.mediaType == "" || dt.mediaType == "image") {
                    temp.push(dt)
                }
                else {
                    // console.log("dt", dt);
                }
            })
            console.log("temp", temp);
            setShuffledQuiz(temp)
            setAllQuestions(temp)
        }

    }, [slctdQBSet])

    const getAllQBSets = async () => {
        let questionBankSets = await getAllQuestionBankSets()
        console.log("questionBankSets", questionBankSets);
        setAllQBSets(questionBankSets)
    }

    const exportPDF = (userData) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 50;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Quiz";
        const headers = [["ID", "NAME", "Quiz Result"]];
        const headers2 = [["ID2", "NAME2", "New"]];

        console.log("lllllllllfffff", shuffledQuiz);
        let temp = []

        let content = {
            startY: 50,
            head: headers,
            body: temp,
            bodyStyles: {
                minCellHeight: 70,
            },
            styles: {
                cellPadding: 2,
            },
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 100 },
            },
        };

        // doc.text(title, marginLeft, 40);
        // get the width of the text
        const textWidth = doc.getTextDimensions(title).w;

        // calculate the x coordinate to center the text
        const x = (doc.internal.pageSize.getWidth() - textWidth) / 2;

        // add the text to the document at the center
        doc.text(title, x, 50);

        // add the text to the document at the center
        doc.textWithLink(title, x, 50, { url: ' ' });

        let y = 50;
        let optnList = ["a)", "b)", "c)", "d)"]

        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        // Add a watermark to the footer of every page
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            // doc.text("Watermark", doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, null, null, "center");
        }

        let imageYPos = 0;
        // let doc1 = new jsPDF();
        let imgData = 'https://assets.jobsforher.com/uploads/v3/companies/employer/1439/learning-matters-pvt-ltd-logo-1521793802.png'

        // doc.addImage(imgData, 'PNG', 0, 0, 50, 50)
        const a = 10; // left margin
        const b = 10; // top margin
        const width = 50;
        const height = 50;

        shuffledQuiz.map(async (dt, i) => {
            console.log("qqqqqqqqqqqqqqqqqqqqqqqqq", dt.question);
            let qstnText = `${i + 1}) ${dt.question}`
            let textHeight = doc.getTextDimensions(qstnText).h;
            let textWidth = doc.getTextWidth(qstnText);
            // if (y + textHeight > doc.internal.pageSize.height - 20) {
            //     // Add a new page and reset the y-position
            //     doc.addPage();
            //     y = 30;
            // }
            y += 30;
            doc.setFont("helvetica", "normal");
            // doc.setTextColor("black");
            // Check if the text will exceed the right margin of the page
            if (textWidth > doc.internal.pageSize.width - 40) {
                // If it does, split the text into multiple lines
                var lines = doc.splitTextToSize(qstnText, doc.internal.pageSize.width - 40);
                // Add each line to the PDF
                for (var j = 0; j < lines.length; j++) {
                    doc.text(lines[j], 20, y);
                    y += textHeight + 10; // Add some padding between lines
                }
            } else {
                // If the text fits on one line, add it to the PDF
                doc.text(qstnText, 20, y);
                y += textHeight + 10; // Add some padding between questions
            }
            // doc.addImage(imgData, 'PNG', a, b, width, height);

            dt.answerOptions.map((optn, optnNum) => {
                if (dt.correctAns.includes(optn)) {
                    // doc.setTextColor(55, 202, 55);
                    // doc.setFont("helvetica", "bold");
                    // Loop through pages and add image to each page

                    doc.text(`${optnList[optnNum]} ${optn}`, 50, y)
                }
                else {
                    // doc.setTextColor("black");
                    doc.setFont("helvetica", "normal");
                    doc.text(`${optnList[optnNum]} ${optn}`, 50, y)
                }
                y += 25;
                if (optnNum == 0) {
                    imageYPos = y;
                }
            })
            console.log("-------------------", dt.mediaPath);
            // if (dt.mediaPath) {
            //     // const url = gets3Image("Questionno7.png");
            //     // console.log("Sssssssssss", url);
            //     // let tempUrl = "data:image/png;base64," + url

            //     const url = await gets3Image("Questionno7.png");
            //     let tempUrl = "data:image/png;base64," + url
            //     console.log("imageData", url);

            //     doc.addImage(tempUrl, 'PNG', 250, imageYPos - 50, 100, 100);
            // }
            if (dt.mediaPath) {
                try {
                    const url = await gets3Image("Questionno7.png");
                    const dataUrl = `data:image/png;base64,${url}`;

                    // Log the image data to the console for debugging purposes
                    console.log("imageData", url, dataUrl);

                    // Add the image to the PDF
                    doc.addImage(dataUrl, 'PNG', 250, imageYPos - 50, 100, 100);

                } catch (error) {
                    console.error("Error adding image to PDF:", error);
                }
            }

            if ((i + 1) % 4 == 0) {
                y = 0;
                y = 30;
                doc.text(`Watermark ${showQB}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, null, null, "center");
                doc.addImage(imgData, 'PNG', 20, 0, 50, 50);
                doc.addPage()
            }
        })

        doc.save("quiz.pdf")
    }

    // Load the SVG file using XMLHttpRequest
    // var xhr = new XMLHttpRequest();
    // xhr.open('GET', 'image.svg', true);
    // xhr.onreadystatechange = function () {
    //     if (xhr.readyState === 4 && xhr.status === 200) {
    //         // Create a new Image object using the loaded SVG data
    //         var svgData = xhr.responseText;
    //         var img = new Image();
    //         img.src = 'data:image/svg+xml;base64,' + btoa(svgData);

    //         // Add the SVG image to the PDF
    //         var doc = new jsPDF();
    //         doc.addImage(img, 'SVG', 10, 10, 100, 100);
    //         doc.save('output.pdf');
    //     }
    // };
    // xhr.send();

    const suffleQuizQstns = (e) => {
        e.preventDefault()
        let numbers = [];

        // Fill the array with numbers 1 to 10
        for (let i = 0; i <= (allQuestions.length - 1); i++) {
            numbers.push(i);
        }

        // Shuffle the array using the Fisher-Yates algorithm
        for (var i = numbers.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = numbers[i];
            numbers[i] = numbers[j];
            numbers[j] = temp;
        }

        // Return the first 10 numbers of the shuffled array
        let shuffledNum = numbers.slice(0, allQuestions.length);

        let temp = [];
        // for (let n = 0; n < shuffledNum - 1; n++) {
        //     console.log("nnnnnnnnnnn", n);
        //     temp.push(questions1[shuffledNum[n]])
        // }
        numbers.map((n, m) => {
            temp.push(allQuestions[n])
        })
        setShuffledQuiz(temp)
        console.log("origianl questions", allQuestions);
        console.log("numberssssssssssss", numbers);
        console.log("Shuffled qyestion list", temp);
    }

    const [imgData, setImageData] = useState("")

    // const PrintImage = () => {
    //     let getData = async () => {
    //         console.log("ccccccccccccccccccccc", await gets3Image());
    //         let dt = 
    //             setImageData()
    //     }

    //     console.log("sssssssssss", getData());
    //     // return <img src={url} />
    //     return <img src={imgData} alt="" width={"100px"} height={"100px"} />
    // }

    const PrintImage = (props) => {
        const [imageData1, setImageData1] = useState(null);
        useEffect(() => {
            async function getImage() {
                const url = await gets3Image(props.imageName);
                let tempUrl = "data:image/png;base64," + url
                console.log("imageData", url);
                setImageData1(tempUrl);
            }
            getImage();
        }, []);

        if (!imageData1) {
            return <p>Loading...</p>;
        }
        return <img src={imageData1} width={"150px"} />
        // return <img src={"data:image/gif;base64,R0lGODdhMAAwAPAAAAAAAP///ywAAAAAMAAw AAAC8IyPqcvt3wCcDkiLc7C0qwyGHhSWpjQu5yqmCYsapyuvUUlvONmOZtfzgFz ByTB10QgxOR0TqBQejhRNzOfkVJ+5YiUqrXF5Y5lKh/DeuNcP5yLWGsEbtLiOSp a/TPg7JpJHxyendzWTBfX0cxOnKPjgBzi4diinWGdkF8kjdfnycQZXZeYGejmJl ZeGl9i2icVqaNVailT6F5iJ90m6mvuTS4OK05M0vDk0Q4XUtwvKOzrcd3iq9uis F81M1OIcR7lEewwcLp7tuNNkM3uNna3F2JQFo97Vriy/Xl4/f1cf5VWzXyym7PH hhx4dbgYKAAA7"} alt="" width={"100px"} height={"100px"} />;
        // return <img src="data:image/gif;base64,R0lGODdhMAAwAPAAAAAAAP///ywAAAAAMAAw AAAC8IyPqcvt3wCcDkiLc7C0qwyGHhSWpjQu5yqmCYsapyuvUUlvONmOZtfzgFz ByTB10QgxOR0TqBQejhRNzOfkVJ+5YiUqrXF5Y5lKh/DeuNcP5yLWGsEbtLiOSp a/TPg7JpJHxyendzWTBfX0cxOnKPjgBzi4diinWGdkF8kjdfnycQZXZeYGejmJl ZeGl9i2icVqaNVailT6F5iJ90m6mvuTS4OK05M0vDk0Q4XUtwvKOzrcd3iq9uis F81M1OIcR7lEewwcLp7tuNNkM3uNna3F2JQFo97Vriy/Xl4/f1cf5VWzXyym7PH hhx4dbgYKAAA7" ALT="Larry" />
    }

    const handleSelect = (dt) => {
        const selectedQbSet = allQBSets.find((qbSet) => qbSet.name === dt);
        setSlctdQbSet(selectedQbSet.questionBankSet);
        setSelectedQBName(dt);
    };


    return (
        <div >
            <form className="mt-3 d-flex align-items-center">
                <label className="me-2"><b>Select Question Bank:</b></label>
                {/* <select className="ms-2" style={{ backgroundColor: "white", color: "black", minWidth: "50px", padding: "0px", height: "30px", border: "1px solid lightgrey" }}>
                    {
                        allQBSets.length > 0 ? allQBSets.map((dt, i) => {
                            console.log("allQBSets", allQBSets)
                            console.log("dt", dt);
                            console.log("dt.questionBankSet", dt.questionBankSet);
                            return <option key={dt._id} value={dt.name} onClick={() => {
                                setSlctdQbSet(dt.questionBankSet)
                                console.log("===========");
                            }}>{dt.name}</option>
                        }) : null
                    }
                </select>  */}
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        {selectedQBName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                        {allQBSets.length > 0 &&
                            allQBSets.map((dt) => (
                                <Dropdown.Item
                                    key={dt._id}
                                    onClick={() => handleSelect(dt.name)}
                                >
                                    {dt.name}
                                </Dropdown.Item>
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
                <button className="prim-btn ms-2" onClick={suffleQuizQstns}>Shuffle</button>
                <button className="prim-btn" onClick={(e) => {
                    e.preventDefault()
                    let temp = []
                    temp.push(shuffledQuiz)
                    exportPDF(temp)
                }}>Download PDF</button>
            </form>
            <section className="mt-3">
                <div>
                    {shuffledQuiz.map((dt, num) => {
                        if (dt != undefined) {
                            return (
                                <div>
                                    <div className="mt-2">
                                        <h4>
                                            {num + 1}) {dt.question}
                                        </h4>
                                        <div className="d-flex">
                                            <div className="d-flex flex-column ms-1" style={{ flex: "0.7" }}>
                                                {
                                                    dt.answerOptions.map((optn, optnNum) => {
                                                        return <h6>
                                                            {answerOptions[optnNum]}) {optn}
                                                        </h6>
                                                    })
                                                }
                                            </div>
                                            <div style={{ flex: "0.3" }}>
                                                {console.log("dt.mediaPath", dt.mediaPath)}
                                                {/* {
                                                    dt.mediaPath ? <img src={getExternalConfigData("Question no 7.png")} width={"110px"} /> : ""
                                                } */}
                                                {
                                                    dt.mediaPath ? <PrintImage imageName={dt.mediaPath} /> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </section>
        </div>
    )
}

export default GenerateQuiz;