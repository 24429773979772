// import { TextField } from "@mui/material";
// import axios from "axios";
// import React, { useContext, useEffect, useState } from "react";
// import { getAllSchools } from "../../Services/api";
// import { UserContext } from "../Context/Reducer";


// function SchoolsList(props) {
//     const [showPanel, setShowPanel] = useState(false);
//     const [slctVal, setSlctVal] = useState("");
//     const [filteredItems, setFilteredItems] = useState([]);
//     const [allSchoolsData, setAllSchoolsData] = useState([]);
//     const { state, dispatch } = useContext(UserContext) //context dt


//     const onChangeSearch = (e) => {
//         // let temp = allSchoolsData.filter((obj) => {
//         //     setShowPanel(true);
//         //     return (
//         //         obj[0].school_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
//         //         obj[0].school_code.toLowerCase().includes(e.target.value.toLowerCase())
//         //     );
//         // });
//         let temp = allSchoolsData.filter((obj) => {
//             console.log("Sssssssssssssssss", obj.schoolDt.schoolName);
//             setShowPanel(true);
//             // setShowPanel(false);
//             return (
//                 obj.schoolDt.schoolName.toLowerCase().includes(e.target.value.toLowerCase()) ||
//                 obj.schoolDt.schoolCode.toLowerCase().includes(e.target.value.toLowerCase())
//             );
//         });
//         setSlctVal(e.target.value);

//         setFilteredItems(temp);
//     };
//     useEffect(async () => {
//         // let schoolData = await getAllSchools();
//         // let tempFilteredDt = [];
//         // Object.values(schoolData).map((dt, key) => {
//         //     console.log("Sssssssssssss", dt.schoolDt);
//         //     tempFilteredDt.push(dt.schoolDt)
//         // })
//         setAllSchoolsData(await getAllSchools())
//     }, [])
//     return (
//         <div className={props.flex ? "d-flex" : ""}>
//             <div style={{ position: "relative" }}>
//                 <TextField size="medium" value={slctVal} name="user_email" id="outlined-basic" onChange={onChangeSearch} label="Enter School Name" variant="outlined" style={{ width: "100%" }} />

//                 {/* <input
//                     value={slctVal}
//                     className="w-100"
//                     placeholder="Enter School Name"
//                     style={{ maxWidth: "200px" }}
//                     type="text"
//                     onChange={onChangeSearch}
//                 /> */}
//                 {showPanel ? (
//                     <div
//                         className="w-100 display-panel"
//                         style={{ zIndex: "10", backgroundColor: "white", padding: "15px", position: "absolute", left: "0px", maxWidth: "200px", boxShadow: "0px 10px 10px #D0D0D0" }}
//                     >
//                         {
//                             filteredItems.slice(0, 10).map((dt, key) => {
//                                 return (
//                                     <div>
//                                         <span
//                                             onClick={() => {
//                                                 setSlctVal(dt.schoolDt.schoolName);
//                                                 if(props.onSchoolClick){
//                                                     props.onSchoolClick()
//                                                 }
//                                                 dispatch({
//                                                     type: "addSlctdSchlId",
//                                                     payload: {
//                                                         school_name: dt.schoolDt.schoolName,
//                                                         accnt_id: dt.id,
//                                                         batches: dt.schoolDt.quizBatches
//                                                     }
//                                                 });
//                                                 props.setSlctSchool(dt)
//                                                 setShowPanel(false);
//                                             }}
//                                             key={dt[0]}
//                                             style={{ cursor: "pointer" }}
//                                         >
//                                             {dt.schoolDt.schoolName}
//                                         </span>
//                                     </div>
//                                 );
//                             })
//                         }
//                         {filteredItems.length > 0 ? "" : "No Results Found"}
//                     </div>
//                 ) : (
//                     ""
//                 )}
//             </div>
//         </div>
//     );
// }
// export default SchoolsList;




import { TextField } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getAllSchools } from "../../Services/api";
import { UserContext } from "../Context/Reducer";
function SchoolsList(props) {
    const [showPanel, setShowPanel] = useState(false);
    const [slctVal, setSlctVal] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const [allSchoolsData, setAllSchoolsData] = useState([]);
    const [error, setError] = useState(false); // Add error state
    const { state, dispatch } = useContext(UserContext); //context dt


    const onChangeSearch = (e) => {
        let key = e.target.value?.trim().toLowerCase()
        let temp = allSchoolsData?.filter((obj) => {
            setShowPanel(true);
            if (obj.schoolDt && obj.schoolDt.schoolName && obj.schoolDt.schoolCode) {
                return (
                    obj.schoolDt.schoolName.toLowerCase().includes(key) ||
                    obj.schoolDt.schoolCode.toLowerCase().includes(key)
                );
            }
            return false;
        });
        props.setSlctSchool([])
        setSlctVal(e.target.value);
        setFilteredItems(temp);
    };



    useEffect(async () => {
        try {
            setAllSchoolsData(await getAllSchools());
        } catch (error) {
            setError(true); // Set error state if API call fails
        }
    }, []);

    const handleSchoolClick = (dt) => {
        setSlctVal(dt.schoolDt.schoolName);
        if (props.onSchoolClick) {
            props.onSchoolClick();
        }
        dispatch({
            type: "addSlctdSchlId",
            payload: {
                school_name: dt.schoolDt.schoolName,
                accnt_id: dt.id,
                batches: dt.schoolDt.quizBatches,
            },
        });
        props.setSlctSchool(dt);
        setShowPanel(false);
    };

    return (
        <div className={props.flex ? "d-flex" : ""}>
            <div style={{ position: "relative" }}>
                <TextField
                    size="medium"
                    value={slctVal}
                    name="user_email"
                    id="outlined-basic"
                    onChange={onChangeSearch}
                    onClick={onChangeSearch}
                    // onBlur={onChangeMouse}
                    label="Enter Customer Name"
                    variant="outlined"
                    autoComplete="off"
                    style={{ width: "100%" }}
                />
                {showPanel && (
                    <div
                        className="w-100 display-panel"
                        style={{
                            zIndex: "10",
                            backgroundColor: "white",
                            padding: "15px",
                            position: "absolute",
                            left: "0px",
                            width: "100%",
                            boxShadow: "0px 10px 10px #D0D0D0",
                        }}
                    >
                        {filteredItems.length > 0 ? (
                            filteredItems.slice(0, 10).map((dt, key) => (
                                <div key={dt[0]} className="w-100 p-2 school-list-panel" style={{ cursor: "pointer", borderBottom: "1px solid lightgrey" }}>
                                    <button className="school-list-btn"
                                        onClick={() => handleSchoolClick(dt)}
                                    >
                                        {dt.schoolDt.schoolName}
                                    </button>
                                </div>
                            ))
                        ) : (
                            <span>No Customers Found</span>
                        )}
                    </div>
                )}
                {error && <span>Error retrieving customer data</span>} {/* Display error message */}
            </div>
        </div>
    );
}
export default SchoolsList;