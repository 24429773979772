import React, { useState, useRef } from 'react'
//import { Col, Row } from 'react-bootstrap';
//import PrimaryTextBox from '../../CmnCompnts/TextBox';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Box, IconButton, InputLabel, MenuItem, Select, FormControl, Grid, Container, FormHelperText } from '@mui/material';
import PageHeadingLeftAlign from '../page_heading_left_align';
import PageHeading from '../page_heading';
import CustomButton2 from "../common/button_two";
import { Save } from '@mui/icons-material';
import { ErrorOutline } from "@mui/icons-material";
import { basic, selectBoxWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';
import InputLabelText from '../common/input_label_text';
import TooltipIconWithMessage from '../common/toop_tip';
import MessageSnackbar from '../common/message_snackbar';
import LoadingOverlay from '../common/loading_overlay';
import InputTextFieldComponent from '../common/input_text_field';
import DropdownComponent from "../common/dropdown";
import messages from "../../../themes/messages.json";
import CancelButton from '../common/button_cancel';

export default function AddCustomer() {

  const navigate = useNavigate();
  const [__loading, __setLoading] = useState(false);
  const [__errorsMsg, __setErrorsMsg] = useState({});
  const __defaultCustomerDetails = {
    type:"School",
    name: "",
    email: "", 
    contactname: "",
    contactnumber:"",
    udisecode: "",
    lmcode: "",
    address: "",
    city: "",
    district: "",
    state:"",
    pincode: "",
  };
  const [__customerDetails, __setCustomerDetails] = useState(__defaultCustomerDetails);

  const __customerTypeOptions = [
    { value: "School", label: "School" },
    { value: "College", label: "College" },
    { value: "CSR", label: "CSR" },
    { value: "Institute", label: "Institute" },
    { value: "Others", label: "Others" }    
  ];

  const __typeRef = useRef(null);
  const __nameRef = useRef(null);
  const __emailRef = useRef(null);
  const __contactnameRef = useRef(null);
  const __contactnumberRef = useRef(null);
  const __udisecodeRef = useRef(null);
  const __lmcodeRef = useRef(null);
  const __addressRef = useRef(null);
  const __cityRef = useRef(null);
  const __districtRef = useRef(null);
  const __stateRef = useRef(null);
  const __pincodeRef = useRef(null);

  const [__snackbarOpen, __setSnackbarOpen] = useState(false);
  const [__snackbarMessage, __setSnackbarMessage] = useState("");
  const [__snackbarVariant, __setSnackbarVariant] = useState("success");
  
  const __handleCustomerType = (aValue) => {
    __inputValueChange("type",aValue);
    __setErrorsMsg({});
  }

  const __inputValueChange = (aField, aValue) => {
    __setCustomerDetails(aPrev => ({
      ...aPrev,
      [aField]: aValue
    }));

    __setErrorsMsg((prev) => ({
      ...prev,
      [aField]: null,
    }));
  }

  const __triggerSnackbar = (message, variant) => {
    __setSnackbarMessage(message);
    __setSnackbarVariant(variant);
    __setSnackbarOpen(true);
  };

  const __validateUdiseCode = (code) => {
    const udiseCodePattern = /^\d{11,12}$/; 
    return udiseCodePattern.test(code);
  };

  const __handleSnackbarClose = (aEvent, aReason) => {
    if (aReason === "clickaway") {
      return;
    }
    __setSnackbarOpen(false);
  };

  const __onAddCustomer = async() => {    
      let __hasError = false;

      // PIN Validation
      const _pincodeRegex = /^[1-9][0-9]{5}$/;
      if (!__customerDetails.pincode) {
        __setErrorsMsg((prev) => ({
          ...prev,
          pincode: "PIN is required.",
        }));
        //__pincodeRef.current.focus();
        __hasError = true;
      }else if (!_pincodeRegex.test(__customerDetails.pincode)) {
        __setErrorsMsg((prev) => ({
          ...prev,
          pincode: "Please enter a valid PIN code using numbers only.",
        }));
        //__pincodeRef.current.focus();
        __hasError = true;
      }else {
        __setErrorsMsg((prev) => ({
          ...prev,
          pincode: null,
        }));
      }

      // state Validation
      const _stateNameRegex = /^[A-Za-z ]+$/;
      if (!__customerDetails.state) {
        __setErrorsMsg((prev) => ({
          ...prev,
          state: "State is required.",
        }));
        //__stateRef.current.focus();
        __hasError = true;
      }else if (!_stateNameRegex.test(__customerDetails.state)) {
        __setErrorsMsg((prev) => ({
          ...prev,
          state: "Please enter a valid state name using alphabets only.",
        }));
        //__stateRef.current.focus();
        __hasError = true;
      }else {
        __setErrorsMsg((prev) => ({
          ...prev,
          state: null,
        }));
      }

      // district Validation
      const _districtNameRegex = /^[A-Za-z ]+$/;
      if (!__customerDetails.district) {
      __setErrorsMsg((prev) => ({
        ...prev,
        district: "District is required.",
      }));
     // __districtRef.current.focus();
      __hasError = true;
    }else if (!_districtNameRegex.test(__customerDetails.district)) {
      __setErrorsMsg((prev) => ({
        ...prev,
        district: "Please enter a valid district using alphabets only."
      }));
      //__districtRef.current.focus();
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        district: null,
      }));
    }

    // city Validation
    const _cityNameRegex = /^[A-Za-z ]+$/;
    if (!__customerDetails.city) {
      __setErrorsMsg((prev) => ({
        ...prev,
        city: "City is required.",
      }));
      //__cityRef.current.focus();
      __hasError = true;
    }else if (!_cityNameRegex.test(__customerDetails.city)) {
      __setErrorsMsg((prev) => ({
        ...prev,
        city: "Please enter a valid city using alphabets only.",
      }));
      //__cityRef.current.focus();
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        city: null,
      }));
    }

    // Address one Validation
    if (!__customerDetails.address) {
      __setErrorsMsg((prev) => ({
        ...prev,
        address: "Address is required.",
      }));
      //__addressRef.current.focus();
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        address: null,
      }));
    }

    // LM Code Validation
    const _lmcodeRegex = /^[a-zA-Z0-9]+$/;
    if (!__customerDetails.lmcode) {
      __setErrorsMsg((prev) => ({
        ...prev,
        lmcode: "LM Code is required.",
      }));
      //__lmcodeRef.current.focus();
      __hasError = true;
    }else if (!_lmcodeRegex.test(__customerDetails.lmcode)) {
      __setErrorsMsg((prev) => ({
      ...prev,
      lmcode: "LM Code should not contain any special characters. Please use letters and numbers only.",
      }));
     // __lmcodeRef.current.focus();
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        lmcode: null,
      }));
    }

    // UDISE Code Validation
    if(__customerDetails.type == "School" || __customerDetails.type == "College"){
      if (!__customerDetails.udisecode) {
        __setErrorsMsg((prev) => ({
          ...prev,
          udisecode: "UDISE Code is required.",
        }));
        //__udisecodeRef.current.focus();
        __hasError = true;
      }else if (!__validateUdiseCode(__customerDetails.udisecode)) {
        __setErrorsMsg((prev) => ({
        ...prev,
        udisecode: "Please enter a valid 11 or 12-digit UDISE code (numbers only).",
        }));
        //__udisecodeRef.current.focus();
        __hasError = true;        
      } else {
        __setErrorsMsg((prev) => ({
          ...prev,
          udisecode: null,
        }));
      }
    }

    // Contact Person Number Validation
    const _contactNumberRegex = /^\d{10}$/;
    if (!__customerDetails.contactnumber) {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactnumber: "Contact person number is required.",
      }));
      //__contactnumberRef.current.focus();
      __hasError = true;
    }else if (!_contactNumberRegex.test(__customerDetails.contactnumber)) {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactnumber: "Please enter a valid 10-digit mobile number (numbers only).",
      }));
     // __contactnumberRef.current.focus();
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactnumber: null,
      }));
    }

    // Contact Person Name Validation
    const _contactPersonNameRegex = /^[A-Za-z .,'\-]+$/;
    if (!__customerDetails.contactname) {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactname: messages.errors.contactPersonRequiredMsg,
      }));
      //__contactnameRef.current.focus();
      __hasError = true;
    }else if (!_contactPersonNameRegex.test(__customerDetails.contactname)) {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactname: messages.errors.contactPersonValidMsg,
      }));
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactname: null,
      }));
    }

    // Email Validation
    const _emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!__customerDetails.email) {
      __setErrorsMsg((prev) => ({
        ...prev,
        email: messages.errors.emailRequiredMsg,
      }));
      //__emailRef.current.focus();
      __hasError = true;
    }else if (!_emailRegex.test(__customerDetails.email)) {
      __setErrorsMsg((prev) => ({
        ...prev,
        email: messages.errors.emailValidMsg,
      }));
      //__emailRef.current.focus();
      __hasError = true;      
    }else {
        __setErrorsMsg((prev) => ({
          ...prev,
          email: null,
        }));
    }

    // Name Validation
    const _nameRegex = /^[A-Za-z0-9 .,'\-:&()]+$/ // Allows letters, numbers, spaces, hyphens, and apostrophes
    if (!__customerDetails.name) {
      __setErrorsMsg((prev) => ({
        ...prev,
        name: messages.errors.nameRequiredMsg,
      }));
      //__nameRef.current.focus();
      __hasError = true;
    }else if (!_nameRegex.test(__customerDetails.name)) {
      __setErrorsMsg((prev) => ({
      ...prev,
      name: messages.errors.nameAllowedMsg,
      }));
      //__nameRef.current.focus(); 
      __hasError = true;
    } else {
      __setErrorsMsg((prev) => ({
        ...prev,
        name: null,
      }));
    }

    //Customer Type Validation
    if (!__customerDetails.type) {
      __setErrorsMsg((prev) => ({
        ...prev,
        type: messages.errors.customerType,
      }));
      //__typeRef.current.focus();
      __hasError = true;
    }else{
      __setErrorsMsg((prev) => ({
        ...prev,
        type: null,
      }));
    }

    if (__hasError) {
      return;
    }

    __setLoading(true);
    const _addCustomerData = await axios.post("/lmAccounts/addCustomer", __customerDetails);
    if(_addCustomerData.data.status === "existsEmail"){
      __setLoading(false);
      __setErrorsMsg((prev) => ({
        ...prev,
        email: messages.errors.emailExists,
      }));
    }else if(_addCustomerData.data.status === "existslmcode"){
      __setLoading(false);
      __setErrorsMsg((prev) => ({
        ...prev,
        lmcode: messages.errors.lmCodeExists,
      }));
    }else if(_addCustomerData.data.status === "success"){
      __setErrorsMsg({});
      __setCustomerDetails(__defaultCustomerDetails);
      __setLoading(false);
      __triggerSnackbar(messages.success.addCustomer,"success"); 
    }else{
      __setLoading(false);
      __triggerSnackbar(messages.success.addCustomerFailed,"error");  
    }


  }

  const __udiseCodeFieldIsRequiredOrNot = ["School","College"].includes(__customerDetails.type) ? "*" : "";

  const __customerNameLabel = __customerDetails.type ? __customerDetails.type + ": Name" : "Name";
  return (
    <div style={{ marginTop: "0px", position:"relative" }}>

      <LoadingOverlay loading={__loading} />

      <MessageSnackbar
        message={__snackbarMessage}
        variant={__snackbarVariant}
        open={__snackbarOpen}
        onClose={__handleSnackbarClose}
      />

      <Container fluid={true} style={{ padding:"0px 0px 0px 0px"}}>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <PageHeading title="Customer Details" alignment="center" />
          </Grid>
          <Box
            sx={{
              height: "auto",
              width: "50%",
              margin:"auto",
              marginTop:"0px",
              ul: {
                bgcolor: "#f6f6f6",
                color: "#ffffff",
              },
            }}
          >
            <form>
              <Grid container spacing={2} alignItems="center">
                {/* Customer Type */}  
                <Grid item xs={12}> 
                  <DropdownComponent
                    label="Customer Type"
                    value={__customerDetails.type}
                    onChange={(e) => __handleCustomerType(e.target.value)}
                    options={__customerTypeOptions}
                    error={Boolean(__errorsMsg.type)}
                    helperText={__errorsMsg.type || ""}
                    required
                    sx={{ marginBottom: 2, mb:"1px", }}
                  />
                </Grid>           

                {/* Name */}
                <Grid item xs={12}>   
                    <InputTextFieldComponent 
                        required 
                        label={__customerNameLabel}  
                        value={__customerDetails.name}
                        onChange={(e) => __inputValueChange("name",e.target.value)}
                        error={Boolean(__errorsMsg.name)}
                        helperText={__errorsMsg.name || ""}
                    />
                </Grid>

                 {/* Email */} 
                <Grid item xs={12}>                                     
                    <InputTextFieldComponent 
                      label="Email"
                      required         
                      value={__customerDetails.email}
                      onChange={(e) => __inputValueChange("email",e.target.value)}
                      error={Boolean(__errorsMsg.email)}
                      helperText={__errorsMsg.email || ""}
                    />
                </Grid>

                {/* Contact Person Name */}
                <Grid item xs={12}>                                                         
                    <InputTextFieldComponent 
                      required 
                      label="Contact Person Name"
                      value={__customerDetails.contactname}
                      onChange={(e) => __inputValueChange("contactname",e.target.value)}
                      error={Boolean(__errorsMsg.contactname)}
                      helperText={__errorsMsg.contactname || ""}
                    />
                </Grid>

                {/* Contact Person Number */}
                <Grid item xs={12}>                   
                    <InputTextFieldComponent 
                      required 
                      label="Contact Person Number"  
                      value={__customerDetails.contactnumber}
                      onChange={(e) => __inputValueChange("contactnumber",e.target.value)}
                      error={Boolean(__errorsMsg.contactnumber)}
                      helperText={__errorsMsg.contactnumber || ""}
                    />     
                </Grid>
                
                {/* UDISE Code */}
                <Grid item xs={12}>               
                    <InputTextFieldComponent 
                      label="UDISE Code"
                      value={__customerDetails.udisecode}
                      required={["School", "College"].includes(__customerDetails.type)}
                      onChange={(e) => __inputValueChange("udisecode",e.target.value)}
                      error={Boolean(__errorsMsg.udisecode)}
                      helperText={__errorsMsg.udisecode || ""}
                    />
                </Grid>  
                
                {/* LMCode */}
                <Grid item xs={12}>    
                    <InputTextFieldComponent 
                      required    
                      label="LM Code"             
                      value={__customerDetails.lmcode}
                      onChange={(e) => __inputValueChange("lmcode",e.target.value)}
                      error={Boolean(__errorsMsg.lmcode)}
                      helperText={__errorsMsg.lmcode || ""}
                    />
                </Grid>

                {/* Address 1 */}
                <Grid item xs={12}>   
                  <InputTextFieldComponent 
                    required 
                    size="large" 
                    label="Address"
                    multiline
                    rows={3}
                    value={__customerDetails.address}
                    onChange={(e) => __inputValueChange("address",e.target.value)}
                    error={Boolean(__errorsMsg.address)}
                    helperText={__errorsMsg.address || ""}
                  />
                </Grid>

                {/* City */}
                <Grid item xs={12}>   
                  <InputTextFieldComponent 
                    required 
                    label="City/Town"
                    value={__customerDetails.city}
                    onChange={(e) => __inputValueChange("city",e.target.value)}
                    error={Boolean(__errorsMsg.city)}
                    helperText={__errorsMsg.city || ""}     
                    />
                </Grid>

                {/* District */}
                <Grid item xs={12}> 
                  <InputTextFieldComponent 
                      required 
                      label="District"
                      value={__customerDetails.district}
                      onChange={(e) => __inputValueChange("district",e.target.value)}
                      error={Boolean(__errorsMsg.district)}
                      helperText={__errorsMsg.district || ""}     
                  />
                </Grid>

                {/* State */}
                <Grid item xs={12}>  
                  <InputTextFieldComponent 
                    required 
                    label="State"
                    value={__customerDetails.state}
                    onChange={(e) => __inputValueChange("state",e.target.value)}
                    error={Boolean(__errorsMsg.state)}
                    helperText={__errorsMsg.state || ""}    
                  />
                </Grid>

                {/* Pin */}
                <Grid item xs={12}>                  
                  <InputTextFieldComponent 
                      required 
                      label="PIN Code"                       
                      value={__customerDetails.pincode}
                      onChange={(e) => __inputValueChange("pincode",e.target.value)}
                      error={Boolean(__errorsMsg.pincode)}
                      helperText={__errorsMsg.pincode || ""} 
                    />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12} sx={{ mb:2}} style={{ textAlign: "center" }}>
                  <CancelButton backToPage="/adminDashboard/customer" aButtonText="Cancel" />
                  <CustomButton2                   
                    aria-label="Submit"
                    onClick={__onAddCustomer}
                    //style={{margin:"0px 0px 0px 0px"}}
                    >
                      <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
                      <div style={{ marginLeft: 5 }}>Submit</div>
                  </CustomButton2>

                </Grid>
                
              </Grid>
            </form>
          </Box>
        </Grid>
      </Container>

    </div>
  )
}
