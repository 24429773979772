import axios from "axios";
import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";


const DeletePopupAdminComponent = (props) => {
    const { selectedUser } = props;
    const [message, setMessage] = useState("");
    const [confirmValue, setConfirmValue] = useState("");

    const handleClose = () => {
        setConfirmValue("");
        props.handleCloseDeletePopup(false);
    };

    const onDeleteUserClick = (e) => {
        e.preventDefault();
        let payload = {
            userId: props.selectedUser._id,
        }
        axios.post('/admin/deleteUser', payload)
            .then((res) => {
                setMessage(res.data.message)
                setTimeout(() => {
                    handleClose()
                }, 2000);
                console.log("Deleted Successfully");
            })
            .catch((err) => {
                setTimeout(() => {
                    handleClose()
                }, 2000);
                setMessage("Please Try again, Server Error")
                console.log("Error while deleting");
            })
        console.log("payload", payload);
    }

    const handleInputChange = (e) => {
        setConfirmValue(e.target.value);
    };

    const isConfirmValueValid = confirmValue === props.selectedUser.userName;

    return (
        <div>
            <Modal show={props.handleShowDeletePopup} onHide={props.handleCloseDeletePopup} backdrop="static" keyboard={false}>
                <Modal.Header >
                    <Modal.Title> Delete </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>Are you sure you want to delete user: <b>{props.selectedUser.userName}</b></label>
                    </div>
                    <div className='mt-2'>
                        <input placeholder='Please enter your username to confirm delete' style={{ width: "400px" }} value={confirmValue} onChange={handleInputChange} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="primry-txt-btn" onClick={handleClose}>
                        Cancel
                    </button>
                    <button className="btn-latest" disabled={!isConfirmValueValid} onClick={onDeleteUserClick}>
                        Delete
                    </button>
                </Modal.Footer>
                <div>
                    <h6><b> {message} </b></h6>
                </div>
            </Modal>
        </div>
    )
}


export default DeletePopupAdminComponent;