import React from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { basic } from "../../../themes/basic";

const Header = ({ title, showIcon = false, icon: IconComponent }) => {
  return (
    <AppBar
      position="static"
      style={{
        background: "inherit",
        boxShadow: "inherit",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        backgroundColor: basic.redLightColor,
      }}
    >
      <Toolbar style={{ minHeight: "auto", padding:"7px 15px" }}>
        {showIcon && IconComponent && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            style={{ backgroundColor:"transparent", padding:"0px"}}
          >
            <IconComponent 
            sx={{
              '& svg': {
              color: basic.colorWhite,
              }
            }}  
            style={{ backgroundColor:"transparent", padding:"0px"}} />
          </IconButton>
        )}
        <Typography
          variant="h6"
          component="div"
          style={{
            fontSize: basic.headingFontSize,
            color: basic.colorWhite,
            fontWeight: "bold",
            textAlign: "left",
            width: "100%",
            fontFamily: basic.fontFamily
          }}
        >
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
