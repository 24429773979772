// import { create } from 'zustand'

// const initialState = {
//     accountId: "",
//     env: "",
//     adminId: localStorage.getItem("adminId"),
//     allData: [],
//     usersData: [],
//     userLicenses: [],
//     schoolData: [],
//     purchasedMasterLicense: []
// }

// const useGlobalData = create((set, get) => ({
//     accountId: initialState.accountId,
//     usersData: initialState.usersData,
//     userLicenses: initialState.userLicenses,
//     allData: initialState.allData,
//     schoolData: initialState.schoolData,
//     purchasedMasterLicense: initialState.purchasedMasterLicense,
//     adminId: initialState.adminId,
//     env: initialState.env,

//     addEnv : (envDt) => {
//         set((state) => ({ ...state, env: envDt }));
//     },
//     addAccountId: (id) => {
//         set((state) => ({ ...state, accountId: id }));
//     },
//     InitialUpdateAccountsData: (data) => {
//         console.log("Initial Update Accounts Data", data);
//         set((state) => ({
//             ...state,
//             accountId: data._id,
//             allData: data,
//             usersData: data.userData,
//             userLicenses: data.userLicenses,
//             schoolData: data.schoolDetails[0],
//             purchasedMasterLicense: data.purchasedMasterLicense
//         }));
//     },

//     updateFullData: (data) => {
//         set((state) => ({
//             ...state,
//             usersData: data,
//         }));
//     },

//     updateUsersData: (data) => {
//         let previosData = get().usersData;

//         let temp = [...previosData, ...data]
//         console.log("sdadasd", temp);

//         set((state) => ({
//             ...state,
//             usersData: temp,
//         }));
//     }
// }));


// export default useGlobalData;
import { create } from 'zustand'

const initialState = {
    accountId: "",
    env: "",
    adminId: localStorage.getItem("adminId"),
    allData: [],
    usersData: [],
    userLicenses: [],
    schoolData: [],
    purchasedMasterLicense: []
}

const useGlobalData = create((set, get) => ({
    accountId: initialState.accountId,
    usersData: initialState.usersData,
    userLicenses: initialState.userLicenses,
    allData: initialState.allData,
    schoolData: initialState.schoolData,
    purchasedMasterLicense: initialState.purchasedMasterLicense,
    adminId: initialState.adminId,
    env: initialState.env,

    addEnv: (envDt) => {
        set((state) => ({ ...state, env: envDt }));
    },
    addAccountId: (id) => {
        set((state) => ({ ...state, accountId: id }));
    },
    InitialUpdateAccountsData: (data) => {
        console.log("Initial Update Accounts Data", data);
        set((state) => ({
            ...state,
            accountId: data._id,
            allData: data,
            usersData: data.userData,
            userLicenses: data.userLicenses,
            schoolData: data.schoolDetails[0],
            purchasedMasterLicense: data.purchasedMasterLicense
        }));
    },

    updateFullData: (data) => {
        set((state) => ({
            ...state,
            usersData: data,
        }));
    },

    updateUsersData: (data) => {
        let previosData = get().usersData;

        let temp = [...previosData, ...data]
        console.log("sdadasd", temp);

        set((state) => ({
            ...state,
            usersData: temp,
        }));
    },

    updateAlreadyExistingUsersData: (data) => {
        let previousData = get().usersData;

        set((state) => {
            const updatedData = previousData.map((item) => {
                const newData = data.find((newItem) => newItem._id === item._id);
                if (newData) {
                    return { ...item, ...newData };
                }
                return item;
            });
            return {
                ...state,
                usersData: updatedData,
            };
        });
    },

    updateUserGrade: (id, grade) => {
        let previousData = get().userData;
        const updatedData = previousData.map(user => {
            if (user.id === id) {
                return { ...user, grade: grade };
            }
            return user;
        });
        set({ userData: updatedData });
    },
    updateUserLicenseGrade: (id, grade) => {
        let previousData = get().userLicenses;

        // Find the license with the given ID
        const updatedLicense = previousData.find((license) => license._id === id);

        if (updatedLicense) {
            // Update the user grade of the license
            updatedLicense._userGrade = grade;

            // Update the global data with the modified licenses
            set({ userLicenses: previousData });
        }
    },

    createLicense: (data) => {
        let previosData = get().purchasedMasterLicense;

        let temp = [...previosData, ...data]
        console.log("userLicenses", temp);

        set((state) => ({
            ...state,
            purchasedMasterLicense: temp,
        }));
    },

    updateLicenseData: (data) => {
        let previosData = get().usersData;

        let temp = [...previosData, ...data]
        console.log("sdadasd", temp);

        set((state) => ({
            ...state,
            usersData: temp,
        }));
    }
}));


export default useGlobalData;