import { useState } from "react";
import React from "react";
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import logoImg from "../Assets/logo.png";
import axios from "axios";
import { useSpring, animated } from "react-spring";
import Dashboard from "../Dashboard";
import NavBar from "../navBar";
import googleImg from '../Assets/google.png'
import FBImg from '../Assets/fb.png'
import Loading from "../CmnCompnts/Loading";

function BasicRegisterPage(props) {
  const [inputField, setInputField] = useState({
    user_name: "",
    user_email: "",
    user_password: "",
    user_type: "DEFAULT",
  });

  const [user_type, SetUser_type] = React.useState('');

  const [changeColor, SetChangeColor] = useState("0")
  const [loading, SetLoading] = useState(false)
  const [isUserAdded, SetisUserAdded] = useState(false)

  const anim = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const successAnim = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 1000 }
  })

  const onChangeTxt = (e) => {
    if (e.target.type == "button") {
      SetChangeColor(e.target.id)
    }
    setInputField({
      ...inputField,
      [e.target.name]: e.target.value,
    });
  };

  const changeBtnColor = (e) => {
    // SetChangeColor(true);

  }

  const onSubmitBtn = (e) => {
    e.preventDefault();
    SetLoading(true);
    let newUser = {
      user_name: inputField.user_name,
      user_email: inputField.user_email,
      user_password: inputField.user_password,
      user_type: inputField.user_type,
    };

    axios
      .post("/lmAccounts/addAccount", newUser)
      .then((res) => {
        console.log("User Added");
        // props.onSubmitClicked(inputField.user_type);
        SetLoading(false)
        SetisUserAdded(true)
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (event) => {
    SetUser_type(event.target.value);
  };

  return (
    <div>
      {/* <NavBar /> */}
      {
        isUserAdded ?
          <animated.div style={successAnim} id="SUCCESS_FORM">
            {/* <Dashboard /> */}
            <Container maxWidth={"xs"}>
              <Grid container direction="column" rowSpacing={2} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
                <Grid item xs={12} alignItems="center" justifyContent="center">
                  <img src={logoImg} alt="logoImg" style={{ width: "150px", margin: "0px auto", display: "block" }} ></img>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <p>
                    Hello {inputField.user_name}!
                  </p>
                  <label style={{ fontWeight: "bold", color: "green" }}>Registeration succesfull</label>
                  <br />
                  Please verify your email ({inputField.user_email}) by clicking on link we have sent
                </Grid>
              </Grid>
            </Container>
          </animated.div>
          :
          <animated.div style={anim} id="REGISTER_FORM" >
            {loading ? <Loading /> : null}
            <Container maxWidth={"xs"} >
              {/* <Container maxWidth={"xs"} style={{ backgroundColor: "#fbfbfb", border: "1px solid rgb(230, 230, 230)", boxShadow: "5px 5px 5px #eee", marginTop: "75px" }}> */}
              <Grid container direction="column" rowSpacing={3} columnSpacing={{ xs: 5, sm: 2, md: 3 }} >
                {/* <Grid item xs={12} alignItems="center" justifyContent="center">
                  <img src={logoImg} alt="logoImg" style={{ width: "150px", margin: "0px auto", display: "block" }} ></img>
                </Grid> */}
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <label style={{ fontWeight: "600", fontSize: "1.5rem" }}>Register with Learning Matters</label>
                </Grid>
                {/* <Grid item xs={12} container rowSpacing={5} alignItems="center" justifyContent="center" direction="row" >
                  <Grid item xs style={{ textAlign: "center" }}>
                    <Button className="slctPanel" id="1" variant="primary" name="user_type" value="PERSONAL" onClick={onChangeTxt} style={changeColor == "1" ? { border: "2px solid #1976d2" } : { border: "none" }} > PERSONAL </Button>
                  </Grid>
                  <Grid item xs style={{ textAlign: "center" }}>
                    <Button className="slctPanel" id="2" variant="primary" name="user_type" value="INSTITUTE" onClick={onChangeTxt} style={changeColor == "2" ? { border: "2px solid #1976d2" } : { border: "none" }} >INSTITUTE/SCHOOL </Button>
                  </Grid>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select your account type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={user_type}
                      label="Select your account type"
                      name="user_type"
                      onChange={handleChange}
                      onClick={onChangeTxt}
                    >
                      <MenuItem value={"PERSONAL"}>PERSONAL</MenuItem>
                      <MenuItem value={"INSTITUTE"}>INSTITUTE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} alignItems="center" justifyContent="center">
                  <TextField size="medium" name="user_name" id="outlined-basic" label="USERNAME" variant="outlined" style={{ width: "100%", backgroundColor: "white" }} onChange={onChangeTxt} />
                </Grid>
                <Grid item xs={12} alignItems="center" justifyContent="center">
                  <TextField size="medium" name="user_email" id="outlined-basic" label="EMAIL" variant="outlined" style={{ width: "100%", backgroundColor: "white" }} onChange={onChangeTxt} />
                </Grid>
                <Grid item xs={12} alignItems="center" justifyContent="center">
                  <TextField size="medium" name="user_password" id="outlined-basic" type="password" label="PASSWORD" variant="outlined" style={{ width: "100%", backgroundColor: "white" }} onChange={onChangeTxt} />
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                  <Button variant={"contained"} style={{ width: "200px", height: "50px", fontWeight: "bold", fontSize: "1rem" }} onClick={onSubmitBtn}> REGISTER </Button>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                  <label>Already have an account?  <a href="/login">Login</a></label>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                  <label>Or Register Using</label>
                </Grid>
                <Grid item container spacing={3} alignItems="center" justifyContent="center">
                  <Grid item >
                    <img src={googleImg} width={"75"}></img>
                  </Grid>
                  <Grid item >
                    <img src={FBImg} width={"50"}></img>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </animated.div>
      }
    </div>
  );
}

export default BasicRegisterPage;
