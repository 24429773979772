import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../navBar";
import studentImg from '../Assets/students.svg';
import teachersImg from '../Assets/teachers.svg';
import adminsImg from '../Assets/admins.svg';
import subscriptionsImg from '../Assets/subscriptions.svg';
import { Grid, Container as MateriaContainer } from "@mui/material";
import axios from "axios";
import Profile_Complete from "../ProfileCompletion/Profile_Complete";
import UserDashboard from "./AllUsersDetails";
import { Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../Context/Reducer";

function Dashboard(props) {

    const [comptnStep, setComptnStep] = useState(0);
    const [schoolSubscriptions, setSchoolSubscriptions] = useState([]);
    const [slctdCategory, setSlctdCategory] = useState("dashboard")

    const navigate = useNavigate();

    const { state, dispatch } = useContext(UserContext)


    // useEffect(() => {
    //     const userEmail = localStorage.getItem("userEmail")
    //     if (userEmail) {
    //         axios.post("/lmAccounts/prflComptn", { userEmail: userEmail })
    //             .then((res, err) => {
    //                 if (res.data.userCompleteProfile <= 4) {
    //                     setComptnStep(res.data.userCompleteProfile)
    //                     setSchoolSubscriptions(res.data.schoolSubscriptions)
    //                 }
    //             }).catch((err) => {
    //                 console.log("couldn't get the data");
    //             })
    //     }
    //     else {
    //         //navigate to login page
    //     }
    // }, [])

    useEffect(() => {
        const _lmAccountId = localStorage.getItem("lmAccountId");
        if (_lmAccountId) {
            axios
                .post("/lmAccountsUser/getUserBasicDetails", { _lmAccountId })
                .then((res, err) => {
                    console.log("bbbbbbbbbbbbbbbbbb", res.data);
                    dispatch({ type: "Set_UserName", payload: { userName: res.data.userName, userEmail: res.data.userEmail } })
                })
                .catch((err) => {
                    console.log("couldn't get the data");
                });
        }
        else {
            navigate('/login')
        }
    }, []);


    const onSelectCategory = (e) => {
        e.preventDefault();
        setSlctdCategory(e.target.value)
        if (e.target.value != "dashboard") {
            navigate(`/dashboard/${e.target.value}`)
        }
        else {
            navigate(`/${e.target.value}`)



        }
    }

    return <Container fluid>
        <NavBar />
        <Row  >
            <Col style={{ position: "relative", maxWidth: "260px" }}>
                <Row>
                    <Col xs={12} className="d-flex flex-column lm-main-dashboard" style={{ backgroundColor: "white", paddingLeft: "0px", maxWidth: "260px" }} >
                        <button onClick={onSelectCategory} value="profile" className={`dashboatdBtns ${slctdCategory === "profile" ? "dashboatdBtnsActive" : ""}`}>Profile</button>
                        <button onClick={onSelectCategory} value="dashboard" className={`dashboatdBtns ${slctdCategory === "dashboard" ? "dashboatdBtnsActive" : ""}`}>Dashboard</button>
                        <button value="schoolDetails" className={`dashboatdBtns ${slctdCategory === "schoolDetails" ? "dashboatdBtnsActive" : ""}`}>School Details</button>
                        <button value="subscriptions" className={`dashboatdBtns ${slctdCategory === "subscriptions" ? "dashboatdBtnsActive" : ""}`}>Subscriptions</button>
                        <button value="analytics" className={`dashboatdBtns ${slctdCategory === "analytics" ? "dashboatdBtnsActive" : ""}`}>Analytics</button>
                        <button  value="notification" className={`dashboatdBtns ${slctdCategory === "notification" ? "dashboatdBtnsActive" : ""}`}>Notifications</button>
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row>
                    {/* <Col xs={12}>
                        {comptnStep > 2 ? "" : <Profile_Complete step={comptnStep} schoolSubscriptions={schoolSubscriptions} />}
                    </Col>
                    <Col xs={12}>
                        {comptnStep > 2 ? <div>
                            <Outlet />
                        </div> : ""}
                    </Col> */}
                    <Col xs={12}>
                        <Outlet />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
}

export default Dashboard;