import React from 'react';
import { Button, Container, Grid, TextField } from "@mui/material";
import DashboardSidebar from './sideBar';
import { Row, Col, Container as ContianerBS } from 'react-bootstrap';

function Dashboard() {
    return (
        <ContianerBS fluid={true} >
            <DashboardSidebar />
        </ContianerBS>
    )
}

export default Dashboard;