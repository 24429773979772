import React from "react";
import { Snackbar, SnackbarContent, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { basic } from '../../../themes/basic';


const MessageSnackbar = ({ open, onClose, message, variant }) => {
    
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      style={{ fontFamily:basic.fontFamily}}
    >
      <SnackbarContent
        style={{ backgroundColor: variant === "error" ? "#f44336" : "#4caf50" }}
        message={<span style={{ fontSize:basic.contentFontSize}}>{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default MessageSnackbar;
