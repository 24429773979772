import { TextField } from '@mui/material';
import { basic, selectBoxWithLabel, textFieldWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';

const InputTextFieldComponent = ({
    label,
    value,
    onChange,
    required = false,
    error = false,
    helperText = "",
    inputRef = null,
    InputProps = {},
    size="small",
    type="text",
    labelShrink = false,
    ...rest
  }) => {
    return (
      <TextField
        type={type}
        size={size}
        label={label}
        value={value}
        onChange={onChange}
        required={required}
        error={error}
        helperText={helperText}
        sx={textFieldAndShrinkStyle}
        inputRef={inputRef}
        InputProps={InputProps}
        /*InputLabelProps={{
            shrink: labelShrink,
            style: { marginTop: "4px", fontSize: basic.labelFontSize, fontFamily: basic.fontFamily }
        }}*/
        variant="outlined"
        fullWidth
        {...rest}
      />
    );
};

export default InputTextFieldComponent;
  