import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function SelectActionCmnt(props) {
    const navigate = useNavigate();


    const onClickSwtich = (e) => {
        e.preventDefault()
        navigate(`${e.target.value}`)
    }
    return (
        <div className='w-100'>
            <select className='dropdown-slct-action' onChange={(e) => {
                e.preventDefault();
                onClickSwtich(e)
            }}>
                <option value={''}>Select Action</option>
                <option value={"/addNewSchool"}>Add New School</option>
                <option value={"/selectSchool"}>Select School</option>
                <option value={"/adminDashboard"}>Main Dashboard</option>
                {/* <option value={"/general"}>General</option> */}
            </select>
        </div>
    )
}
