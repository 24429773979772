import { Button, Grid } from "@mui/material";
import { Container } from "react-bootstrap";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ShowUserModel from "./ShowUserModal";
import PendingUserTable from "./Tables/PendingUserTable";

function PendingUsers(props) {
    const [userData, SetUserData] = useState([])
    const [modalShow, setModalShow] = React.useState(false);
    const [dtForModal, setDtForModal] = React.useState([]);

    useEffect(() => {
        axios.get("/authorUser/get_users")
            .then((res) => {
                console.log("Getting Users: ", res.data);
                SetUserData(res.data)
            })
            .catch((Err) => {
                console.log("Errorr", Err);
            })
    }, [])

    return <Container maxWidth={false} style={{ marginTop: "20px" }}>
        <h4>Pending Users</h4>
        {
            userData.filter((e) => { return e.approved === "false" }).length > 0 ? <PendingUserTable userData={userData.filter((e) => { return e.approved === "false" })} setModalShow={setModalShow} setDtForModal={setDtForModal} dbID={props.dbID} /> : "No Pending users"
        }

        {
            dtForModal.user_name === undefined ? "" : <ShowUserModel
                data={dtForModal}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        }
    </Container>
}

export default PendingUsers;