import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { TextField } from '@mui/material'
import Dropzone from "./Dropzone";
import DownloadButton from "./DownloadCsvButton";
import axios from "axios";
import { useRef } from "react";


const QuestionBankForm = () => {
    const [number, setNumber] = useState();
    const [errorMessage, setErrorMessage] = useState("")
    const [csvData, setCsvData] = useState()
    const [quizSetNameError, setQuizSetNameError] = useState("")
    const [quizDescriptionError, setQuizDescriptionError] = useState("")
    const [subjectNameError, setSubjectNameError] = useState("")
    const [minimumMarks, setMinimumMarks] = useState("")
    const [csvDataError, setCsvDataError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [cefrLevels, setCefrLevels] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setShowConfirmationModal(false);
        setShowSuccessModal(false);
        setSuccessMessage("");
        reloadButton()
    };

    const handleShow = () => setShow(true);

    const checkValue = [
        {
            level: "A1",
            value: 0
        },
        {
            level: "A2",
            value: 1
        },
        {
            level: "B1",
            value: 2
        },
        {
            level: "B2",
            value: 3
        },
        {
            level: "C1",
            value: 4
        },
        {
            level: "C2",
            value: 5
        },
    ]

    const findHighestLevelBetween = (level1, level2) => {
        let value1 = checkValue.find((item) => item.level === level1)?.value;
        let value2 = checkValue.find((item) => item.level === level2)?.value;

        let highestValue = Math.max(value1, value2);

        // let highestLevel = null;
        // checkValue.forEach((item) => {
        //     if (item.value === highestValue) {
        //         highestLevel = item.level
        //     }
        // });

        return highestValue;
    }

    // const checkBoxData = [
    //     {
    //         levels: ["A1", "A2"]
    //     },
    //     {
    //         levels: ["B1", "B2"]
    //     },
    //     {
    //         levels: ["C1", "C2"]
    //     }
    // ]

    // const [checkbox, setChechbox] = useState(checkBoxData)
    const [formData, setFormData] = useState({
        quizSetName: "",
        quizDescription: "",
        subjectName: "",
        optnShuffle: ""
    })
    const [selectedLevels, setSelectedLevels] = useState([])

    // const digitsRegex = /^\d+$/;
    const digitsAndSpecialCharsRegex = /^[\d\W]+$/;

    const handleChange = (e) => {
        console.log("formdata", formData);
        e.preventDefault();
        const inputValue = e.target.value.trim();
        if (inputValue === '') {
            if (e.target.name === 'quizSetName') {
                setQuizSetNameError('Quiz Set Name cannot be empty or contain only spaces');
            } else if (e.target.name === 'quizDescription') {
                setQuizDescriptionError('Quiz Description cannot be empty or contain only spaces');
            } else if (e.target.name === 'subjectName') {
                setSubjectNameError('Subject Name cannot be empty or contain only spaces');
            }
            setIsButtonDisabled(true);
        } else if (digitsAndSpecialCharsRegex.test(inputValue)) {
            if (e.target.name === 'quizSetName') {
                setQuizSetNameError('Quiz Set Name cannot contain only numbers and only special characters');
            } else if (e.target.name === 'quizDescription') {
                setQuizDescriptionError('Quiz Description cannot contain only numbers and only special characters');
            } else if (e.target.name === 'subjectName') {
                setSubjectNameError('Subject Name cannot contain only numbers and only special characters');
            }
            setIsButtonDisabled(true);
        } else {
            setFormData({
                ...formData,
                [e.target.name]: inputValue
            });
            if (e.target.name === "quizSetName" && inputValue.length > 0) {
                setQuizSetNameError("");
                setIsButtonDisabled(false);
            } else if (formData.quizSetName.length === 1 && e.target.name === "quizSetName") {
                setIsButtonDisabled(true);
            }
            if (e.target.name === "quizDescription" && inputValue.length > 0) {
                setQuizDescriptionError("");
            }
            if (e.target.name === "subjectName" && inputValue.length > 0) {
                setSubjectNameError("");
            }
        }
    }

    // const numberValidation = (e) => {
    //     const insertedValue = e.target.value.replace(/\D/g, "")
    //     if (e.target.value == insertedValue) {
    //         setErrorMessage("")
    //         setNumber(insertedValue)
    //     } else {
    //         setNumber(insertedValue)
    //         console.log(errorMessage);
    //         setErrorMessage("Please add only numbers")
    //     }
    // }

    const numberValidation = (e) => {
        const insertedValue = e.target.value.replace(/\D/g, "")
        if (insertedValue === "" || parseInt(insertedValue) <= 0) {
            setErrorMessage("Please add a number greater than 0")
            setNumber("")
        } else if (e.target.value === insertedValue) {
            setErrorMessage("")
            setNumber(insertedValue)
        } else {
            setNumber(insertedValue)
            setErrorMessage("Please add o.nly numbers")
        }
    }

    const reUpload = (e) => {
        e.preventDefault();
        return <div>
            <Dropzone />
        </div>
    }

    const onSubmit = (e) => {
        // e.preventDefault();
        if (formData.quizSetName.length <= 0) {
            setQuizSetNameError("Please enter Quiz Set Name")
            return
        }
        if (!formData.quizDescription) {
            setQuizDescriptionError("Please enter Quiz Description")
            return
        }
        if (!formData.subjectName) {
            setSubjectNameError("Please enter Subject Name")
            return
        }
        if (!csvData) {
            setCsvDataError("Please upload a CSV file")
            return
        }
        // if (number.length <= 0) {
        //     console.log("Please Enter Minimum Marks");
        //     // setErrorMessage("Please Enter Minimum Marks ")
        //     return
        // }
        let newQuestionBank = {
            name: formData.quizSetName,
            description: formData.quizDescription,
            subjectName: formData.subjectName,
            proficiencyLevel: cefrLevels,
            resultMinMarks: number,
            questionBankSet: csvData,
        }
        console.log("newQuestionBank", newQuestionBank);
        axios.post("/questionBank/addQuestionBank", newQuestionBank)
            .then((res) => {
                console.log("Success ");
                setSuccessMessage("Successfully Created Question Bank")
                setCsvDataError("");
                // add these lines to show the success message modal
                setShowConfirmationModal(false);
                setShowSuccessModal(true);
            })
    }

    // function postData(aPath, aDataToSet, aOnPromise){
    //     axios.post(aPath, aDataToSet)
    //     .then((res) => {
    //         aOnPromise()
    //     }) 
    // }
    // //postData("/questionBank/addQuestionBank", newQuestionBank,promiseFun)

    const reloadButton = () => {
        window.location.reload();
    }

    // const setCsvData1 = (data) => {
    //     setCsvDataError(""); // Clear the error message
    //     setCsvData(data);
    // };

    useEffect(() => {
        let temp = [];
        console.log("formData", formData);
        if (csvData) {
            let levels = [];
            let counts = {};
            // let counts = [0, 0];
            csvData.map((dt, i) => {
                if (!temp.includes(dt.profcncyLvl)) {
                    temp.push(dt.profcncyLvl)
                    levels.push(dt.profcncyLvl)
                }
                // levels.push(dt.profcncyLvl)

                const value = dt.profcncyLvl;
                console.log("mmmmmmmmmmm", value, "--", counts[value]);
                if (!counts[value]) {
                    counts[value] = 0;
                }
                counts[value]++;
            })

            // let counts = {};

            // levels.map((k, i) => {
            //     const value = k;
            //     if (!counts[value]) {
            //         counts[value] = 0;
            //     }
            //     console.log("ccccccccccc", counts);
            //     counts[value]++;
            // })

            console.log("-----1111-------", counts);
            setCefrLevels(counts)
        }
    }, [csvData])

    return (
        <>
            <Row>
                <Col>
                    <div className="text-center w-100 " style={{ textAlign: "center", padding: "10px", }}>
                        <Row >
                            <Col xs={3} ></Col>
                            <Col xs={9} style={{ textAlign: "right", position: "relative" }}>
                                <h4 className="pos-absolute-center"><b>Import Question Bank</b></h4>
                                <DownloadButton />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={4} md={3} style={{ borderRight: "1px solid lightgray" }}>
                    <div style={{ backgroundColor: "white", padding: "10px", height: "80vh" }}>
                        <Row>
                            <Col >
                                {/* <div><input type="text" name="quizSetName" value={formData.quizSetName} placeholder="Enter Quiz Set Name" onChange={handleChange} /> </div> */}
                                <div>
                                    <TextField id="outlined-basic" label="Enter Quiz Set Name" variant="outlined" style={{ width: "100%" }} name="quizSetName" onChange={handleChange} />
                                    <h6 className="mt-1" style={{ color: "red" }}>{quizSetNameError}</h6>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                {/* <div > <textarea style={{ height: "150px" }} name="quizDescription" value={formData.quizDescription} placeholder="Enter Quiz Description" onChange={handleChange} /> </div> */}
                                <TextField multiline id="outlined-basic" rows={4} label="Enter Quiz Description" variant="outlined" style={{ width: "100%" }} name="quizDescription" onChange={handleChange}></TextField>
                                <h6 className="mt-1" style={{ color: "red" }}>{quizDescriptionError}</h6>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                {/* <div> <input type="text" name="subjectName" value={formData.subjectName} placeholder="Enter Subject Name" onChange={handleChange} /> </div> */}
                                <TextField multiline id="outlined-basic" label="Enter Subject Name" variant="outlined" style={{ width: "100%" }} name="subjectName" onChange={handleChange} ></TextField>
                                <h6 className="mt-1" style={{ color: "red" }}>{subjectNameError}</h6>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <div>
                                    <h5><u>Proficiency Level</u></h5>
                                    <div >
                                        {cefrLevels ?
                                            Object.entries(cefrLevels).map((dt, k) => {
                                                console.log("zzzzzzzzzzzzzzz", dt);
                                                return <div className="ms-3" key={k}>
                                                    <h6> <b>{dt[0]}</b> {`-- (${dt[1]} questions)`}  </h6>
                                                </div>
                                            }) : ""
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mt-3">
                                    <h5><u>Result</u></h5>
                                    <div className="d-flex">
                                        <h6 >Minimum Marks : </h6>
                                        <input className="ms-2" style={{ maxWidth: "40px" }} name="minMarks" value={number} onChange={numberValidation} />
                                    </div>
                                    <h6 style={{ color: "red" }}>{errorMessage}</h6>
                                </div>
                            </Col>
                            <div style={{ textAlign: "center", marginRight: "30px" }}>
                                <Col>
                                    <button className={`mt-4 w-75 ${isButtonDisabled ? 'sec-btn' : 'prim-btn'}`} onClick={(e) => {
                                        e.preventDefault();
                                        handleShow()
                                        setShowConfirmationModal(true)
                                    }}
                                        disabled={isButtonDisabled}
                                    > Submit </button>
                                    <button className="mt-2 w-75 cancel-btn-qbf" onClick={reloadButton}>Cancel</button>
                                    <Modal show={showConfirmationModal} onHide={handleClose} backdrop="static">
                                        <Modal.Header closeButton className="justify-content-center">
                                            <Modal.Title><h4><b>Confirmation</b></h4></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="justify-content-center">Are you sure you want to submit?</Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn-latest" onClick={onSubmit}>
                                                Yes
                                            </button>
                                            <button className="secondary-btn" onClick={handleClose}>
                                                No
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal show={showSuccessModal} onHide={handleClose} backdrop="static">
                                        <Modal.Header >
                                            <Modal.Title><h4><b>Success</b></h4></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="justify-content-center">{successMessage}</Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn-latest" onClick={reloadButton}>
                                                OK
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </div>
                        </Row>
                    </div>
                </Col>
                <Col xs={8} md={9}>
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <Dropzone setCsvData={setCsvData} />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default QuestionBankForm;