import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'
import sendUserImg from '../../Assets/sendUser.svg';

export default function AssignStudentsToLicense() {
    const [licenseData, setLicenseData] = useState()
    const location = useLocation();
    const [allUsers, setAllUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [assignedUsers, setAssignedUsers] = useState([])
    const [inputFields, setInputFields] = useState({})
    const [newLicense, setNewLicense] = useState("")
    const [errorMsg, setErrMsg] = useState("")
    const [allGrade, setAllGrades] = useState([])

    const navigate = useNavigate();

    useEffect(async () => {
        try {
            setLicenseData(location.state.LicenseData)
            // setLicenseData(LicenseData)
            getAccountUsers()
        } catch (error) {
            console.log("Error ", error);
        }
    }, [])

    const getAccountUsers = (dt) => {
        console.log("get Account users", location.state.LicenseData);
        axios.post('/license/getUsersForLicense/', { _lmAccountId: location.state.LicenseData._lmAccountId }).then((res) => {
            console.log("REsponse", res.data);
            setAllUsers(res.data.allUsersData)
            let createTeachersDataWithSeperateGrades = []
            res.data.allUsersData.map((dt, i) => {
                if (dt.userRole === "teacher") {
                    let gradedDt = dt.grade.split(",")
                    gradedDt.map((gradeDt, k) => {
                        createTeachersDataWithSeperateGrades.push({ ...dt, grade: gradeDt })
                    })
                }
                else {
                    createTeachersDataWithSeperateGrades.push(dt)
                }
            })
            console.log("All dataaaaaaaaaaaa", createTeachersDataWithSeperateGrades);
            // setFilteredUsers(res.data.allUsersData)
            setFilteredUsers(createTeachersDataWithSeperateGrades)

            let gradesTemp = [];
            res.data.allUsersData.map((dt, i) => {
                if (!gradesTemp.includes(dt.grade)) {
                    gradesTemp.push(dt.grade);
                }
            })
            setAllGrades(gradesTemp)
        })
            .catch((err) => {
                console.log("Erroe ", err);
            })
    }

    const sendUserToAssigned = (user_id) => {
        let tempUser = [...assignedUsers];
        if (tempUser.includes(user_id)) {
            let findIndex = tempUser.findIndex(user_id)
            tempUser.splice(findIndex, 1)
        }
        else {

            tempUser.push(user_id);
        }

        setAssignedUsers(tempUser)
    }

    const sendBackUserFromAssigned = (user_id) => {
        let tempUser = [...assignedUsers];

        const removeUser = tempUser.filter(function (user) {
            return user._id !== user_id._id;
        });

        setAssignedUsers(removeUser)
    }

    const assignLicense = () => {
        if (inputFields.courseId) {
            let payload = {
                allAssignedUsers: assignedUsers,
                selectedProduct: inputFields,
                licenseDetails: location.state.LicenseData
            }
            axios.post('/license/assignUsersToLicenses', payload)
                .then((res) => {
                    console.log("Successfully assigned the users", res.data);
                    navigate(0)
                })
                .catch((err) => {
                    console.log("Error came ", err);
                })
        }
        else {
            setErrMsg("Please Select Product")
        }
    }

    const onChangeTxt = (e) => {
        e.preventDefault()
        setInputFields({
            ...inputFields,
            [e.target.name]: e.target.value
        })


        if (e.target.name === "courseId") {
            setNewLicense(e.target.value)
        }
    }

    const filterByGrade = (gradeVal) => {
        let tempUsers = [...allUsers];
        if (gradeVal === "all") {
            setFilteredUsers(allUsers)
        }
        else {
            let filteredUsersDt = tempUsers.filter((dt) => {
                return dt.grade === gradeVal
            })
            setFilteredUsers(filteredUsersDt)
        }
    }

    return (
        <div>
            {
                licenseData ? <div>
                    <Row>
                        <Col xs={12}>
                            <div>
                                License details
                            </div>
                            <div>
                                Total Units: {location.state.LicenseData.total_units}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={12}>
                                    <div className='mt-4 d-flex justify-content-between'>
                                        <h4><b>Select Products</b></h4>
                                    </div>
                                </Col>
                                <Col xs={3}>
                                    <div>
                                        <select name='productId' onChange={onChangeTxt}>
                                            <option>Select Product</option>
                                            <option value={"Tara English/1"}>Tara English</option>
                                            <option value={"Tara Science/2"}>Tara Science</option>
                                            <option value={"Tara Math/3"}>Tara Math</option>
                                            <option value={"Tara Social Studies/4"}>Tara Social Studies</option>
                                            <option value={"Tara Digitial Literacy/5"}>Tara Digitial Literacy</option>
                                            <option value={"Tara Accountancy/6"}>Tara Accountancy</option>
                                            <option value={"Tara General Knowledge/7"}>Tara General Knowledge</option>
                                        </select>
                                    </div>
                                </Col>

                                <Col xs={3}>
                                    <div>
                                        <select name='productVariant' onChange={onChangeTxt}>
                                            <option>Select Product Variant</option>
                                            <option value={"Early Child Education/1"}>Early Child Education</option>
                                            <option value={"Young Learners/2"}>Young Learners</option>
                                            <option value={"Adult Learners/3"}>Adult Learners</option>
                                            <option value={"Star Teacher Training/4"}>Star Teacher Training</option>
                                            <option value={"Custom Sebor/5"}>Custom Sebor</option>
                                            <option value={"Professional Executive/6"}>Professional Executive</option>
                                        </select>
                                    </div>
                                </Col>

                                <Col xs={3}>
                                    <div>
                                        <select name='courseId' onChange={onChangeTxt}>
                                            <option>Select Course Name</option>
                                            <option value={"Plus/1"}>Plus</option>
                                            <option value={"Neo/2"}>Neo</option>
                                            <option value={"Champ/3"}>Champ</option>
                                            <option value={"Expert/4"}>Expert</option>
                                            <option value={"Master/5"}>Master</option>
                                            <option value={"Star/6"}>Star</option>
                                            <option value={"Pro/1"}>Pro</option>
                                            <option value={"Ace/2"}>Ace</option>
                                            <option value={"Elite/3"}>Elite</option>
                                            <option value={"Jazz/4"}>Jazz</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='mt-4 d-flex justify-content-between'>
                                <h4><b>Assign Students</b></h4>
                                <button className='btn-latest' onClick={(e) => {
                                    e.preventDefault();
                                    assignLicense()
                                }}>Assign License</button>
                            </div>
                            <div className='text-end' style={{ fontWeight: "bold", color: "red" }}>
                                {errorMsg}
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className='mt-3'>
                                <h5 ><b>All Students</b></h5>
                                <div>
                                    Filter <select onChange={(e) => {
                                        e.preventDefault();
                                        filterByGrade(e.target.value)
                                    }}>
                                        <option value={"all"}>Select Grade</option>
                                        <option value={"all"}>All</option>
                                        {
                                            allGrade.length > 0 ? allGrade.map((dt, i) => {
                                                return <option value={dt}>
                                                    {dt}
                                                </option>
                                            }) : "No Grade Found"
                                        }
                                    </select>
                                </div>
                            </div>
                            <div>
                                <Table>
                                    <thead>
                                        <tr>
                                            <td>Name</td>
                                            <td>Grade </td>
                                            <td>Role </td>
                                            <td>Already Allocated </td>
                                            <td>New </td>
                                        </tr>
                                    </thead>
                                    {
                                        filteredUsers.length > 0 ?
                                            <tbody>
                                                {
                                                    filteredUsers.map((user, i) => {
                                                        let temp = false;
                                                        let checkIdExistInAssigned = assignedUsers.filter((dt) => {
                                                            if (dt._id === user._id) {
                                                                temp = true;
                                                            }
                                                        });

                                                        let checkUserAlreadyHasLicense = false;
                                                        if (user._licenseId.length > 0) {
                                                            checkUserAlreadyHasLicense = true;
                                                        }

                                                        return <tr>
                                                            <td>
                                                                {/* <button className='sendUserBtn' disabled={assignedUsers.includes(i) ? true : false} style={{ backgroundColor: `transparent`, border: "none" }} onClick={(e) => { */}
                                                                <button className='sendUserBtn' style={{ background: "transparent", color: `${assignedUsers.includes(user._id) ? "green" : "inherit"}`, border: "none" }} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    sendUserToAssigned(user._id)
                                                                }}>
                                                                    {user.userName}
                                                                    <img className='ms-2 sendUser' src={sendUserImg} width={"20px"} />
                                                                </button>
                                                            </td>
                                                            <td>{user.grade}</td>
                                                            <td>{user.userRole}</td>
                                                            <td>{
                                                                user.licenseData ? user.licenseData.map((dt, i) => {
                                                                    return <span>{dt._courseId.split("/")[0]} </span>
                                                                }) : ""
                                                            }</td>
                                                            <td>
                                                                {temp ? newLicense.split("/")[0] : ""}
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody> : ""
                                    }
                                </Table>
                            </div>
                            {/* <div>
                                {
                                    allUsers.length > 0 ? <div>
                                        {
                                            allUsers.map((user, i) => {
                                                let temp = false;
                                                let checkIdExistInAssigned = assignedUsers.filter((dt) => {
                                                    if (dt._id === user._id) {
                                                        temp = true;
                                                    }
                                                });

                                                let checkUserAlreadyHasLicense = false;
                                                if (user._licenseId.length > 0) {
                                                    checkUserAlreadyHasLicense = true;
                                                }

                                                return <div className='d-flex justify-content-between' key={i} style={{ width: "100%", border: "1px solid lightgrey", padding: "5px" }}>
                                                    <button className='sendUserBtn' disabled={temp ? true : false} style={{ backgroundColor: `transparent`, border: "none" }} onClick={(e) => {
                                                        e.preventDefault()
                                                        sendUserToAssigned(user)
                                                    }}>
                                                        {user.userName}
                                                        <img className='ms-2 sendUser' src={sendUserImg} width={"20px"} />
                                                    </button>
                                                    <label style={{ fontSize: "14px" }}>
                                                        {
                                                            checkUserAlreadyHasLicense ? <span>Already Exist in <b>{user._licenseId.length}</b>  License</span> : ""
                                                        }
                                                    </label>
                                                </div>
                                            })
                                        }
                                    </div>
                                        : ""
                                }
                            </div> */}
                        </Col>
                        {/* <Col xs={6}>
                            <div className='mt-3'>
                                <h5 ><b>Assigned Students</b></h5>
                            </div>
                            <div>
                                {
                                    assignedUsers.length > 0 ? <div >
                                        {
                                            assignedUsers.map((dt, i) => {
                                                return <div className='d-flex justify-content-between' style={{ width: "100%", border: "1px solid lightgrey", padding: "5px" }}>
                                                    <button className='sendUserBtn' style={{ backgroundColor: `transparent`, border: "none" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            sendBackUserFromAssigned(dt)
                                                        }}>
                                                        {dt.userName}
                                                        <img className='ms-2 sendUser' src={sendUserImg} width={"20px"} style={{ transform: "rotate(180deg)" }} />
                                                    </button>
                                                </div>
                                            })
                                        }
                                    </div> : "Not yet assigned"
                                }
                            </div>
                        </Col> */}
                    </Row>
                </div> : <div>
                    Data Not Found
                </div>

            }
        </div >
    )
}
