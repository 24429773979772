import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Box, List, Typography, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School'; 
import { basic } from '../../../themes/basic';

const LeftSidebar = ({ menuItems, selectedBtn, activeStyle, hoverStyle, selectedSchool }) => {
    return (
        <>
        {selectedSchool && (
                <Box 
                    sx={{
                        justifyContent: 'space-between',
                        padding: '0px 0px 10px 0px',
                        borderRadius: '0px',
                        marginBottom: '0px',
                        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box sx={{ display: "block", textAlign:"center", width:"100%" }}>
                        <SchoolIcon sx={{ color: basic.greenColor, marginRight: '8px' }} />
                        <Typography style={{color:basic.colorBlack, fontSize:basic.headingFontSize}} variant="subtitle1" fontWeight="bold" color="textPrimary">
                            {selectedSchool}
                        </Typography>
                    </Box>
                </Box>
            )}
            <List>
                {menuItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton 
                            sx={selectedBtn === item.name ? activeStyle : hoverStyle} 
                            onClick={item.onClick}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            </>
    );
};

export default LeftSidebar;
