import { GridEditInputCell } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import CreateQuizPage from "./CreateQuizPage";
import Edit from "../../Assets/edit.svg";
import Delete from "../../Assets/delete.svg";
import axios from "axios";
import CreateBatch from "./CreateBatch";
import { getAllAccountQuizData, getAllQuestionBankSets } from "../../../Services/api";
import DatePicker from "react-datepicker";
import QuizReport from "./QuizReport";
import QuizReportNew from "./QuizReportNew";
import PopUpModel from "../../CmnCompnts/PopUpModel";
import UpdateQuiz from "./UpdateQuiz";
import GenerateQuiz from "./GenerateQuiz";
import NewGenerateQuiz from "./NewGenerateQuiz";

const ManageQuizPage = (props) => {
    const [selectBtn, setSelectBtn] = useState("");
    const [editSchl, setEditSchl] = useState("");
    const [allSchoolsData, setAllSchoolsData] = useState([]);
    const [isError, setIsError] = useState("")
    const [tableDt, setTableDt] = useState()
    const [inputField, setInputField] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [actionType, setActionType] = useState("");
    const [quizDt, setQuizDt] = useState([]);
    const [accntId, setAccntId] = useState("");
    const [selectedQuizId, setSelectedQuizId] = useState("")

    const onChangeTxt = (e) => {
        if (e) {
            e.preventDefault()
            setInputField({
                ...inputField,
                [e.target.name]: e.target.value,
            });
        }
    };

    const onDateClick = (name, date) => {
        setInputField({
            ...inputField,
            ["startDate"]: startDate,
            ["endDate"]: endDate,
        });
    }

    const onSelect = (e) => {
        console.log("Ssssssss", e.target.value);
        setSelectBtn(e.target.value);
    };

    const onCancelClick = () => {
        setSelectBtn("")
    }

    const url = "";
    useEffect(async () => {
        let temp = await getAllAccountQuizData();
        console.log("zzzzzzzzzzzzzzzzzzzzzzz", temp);
        setTableDt(temp)
    }, [])

    const onUpdateCLick = (key, mainId) => {
        console.log("Quiz id", key);
        let payload = {
            id: mainId,
            quiz_id: key,
            quiz_name: inputField.quiz_name,
            batch: inputField.batch,
            start_date: startDate,
            end_date: endDate
        }
        axios.post("/quiz/updateQuiz", payload)
            .then((res) => {
                console.log("Quiz Updated");
            }).catch((err) => {
                console.log("Error", err);
            })
    }

    const onQuizStatus = (key, mainId, status) => {
        let payload = {
            id: mainId,
            quiz_id: key,
            quiz_status: status ? "ACTIVATE" : "DEACTIVATE"
        }
        console.log("Ssssssssssss", payload);
        axios.post("/quiz/updateQuizStatus", payload)
            .then((res) => {
                console.log("Quiz Updated");
            }).catch((err) => {
                console.log("Error", err);
            })
    }

    const onChangeData = () => {
        let temp = [...tableDt]
        // temp = [...temp, temp[selectedQuizId].quizStatus = "Active"]
        // console.log("tempppppppppppp", temp);
        temp.map((dt, k) => {
            if (k === selectedQuizId) {
                if (dt.quizStatus === "Active") {
                    dt.quizStatus = "InActive"
                }
                else {
                    dt.quizStatus = "Active"
                }
            }
        })
        setTableDt(temp)
    }

    return (
        <Container>
            <Row>
                <Col style={{ marginTop: "25px" }}>
                    <div>
                        <button onClick={onSelect} value="CreateQuiz" className="prim-btn"> Create Quiz </button>
                        <button onClick={onSelect} value="QuizReport" className="prim-btn"> Reports </button>
                        <button onClick={onSelect} value="GenerateQuiz" className="m-2 prim-btn"> Generate Quiz</button>
                        {/* <button onClick={onSelect} value="NewGenerateQuiz" className="m-2 prim-btn"> New Generate Quiz</button> */}
                    </div>
                </Col>
                <Col xs={12}>
                    {selectBtn === "CreateQuiz" ? <CreateQuizPage schoolsDt={allSchoolsData} onCancelClick={onCancelClick} /> : ""}
                    {selectBtn === "QuizReport" ? <QuizReportNew schoolsDt={allSchoolsData} /> : ""}
                    {selectBtn === "GenerateQuiz" ? <GenerateQuiz schoolsDt={allSchoolsData} /> : ""}
                    {/* {selectBtn === "NewGenerateQuiz" ? <NewGenerateQuiz schoolsDt={allSchoolsData} /> : ""} */}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <section>
                        <h3><b>All Quiz Details</b></h3>
                    </section>
                </Col>
            </Row>
            <Row>
                {(selectBtn === "QuizReport" || selectBtn === "CreateQuiz" || selectBtn === "GenerateQuiz") && (
                    <Col style={{ marginTop: "25px" }}>
                        <Table responsive bordered hover style={{ backgroundColor: "white" }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>School Name</th>
                                    <th>School Code</th>
                                    <th>Quiz Name</th>
                                    <th>Total Users</th>
                                    <th>Created Date</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            {tableDt ? (
                                <tbody>
                                    {tableDt !== undefined ? (
                                        tableDt.map((data, i) => {
                                            console.log("ddattttttttttttt", data);
                                            let isQuizStarted = false;
                                            let isQuizactive = true;
                                            let currentDateAndTime = Date.parse(new Date());
                                            let startDateTime = Date.parse(`${data.startDate} ${data.startTime}`);
                                            console.log(" sadasdasdasdasd", startDateTime, "--------", currentDateAndTime, "Diff", (startDateTime - currentDateAndTime));
                                            if ((startDateTime - currentDateAndTime) <= 0) {
                                                // if(dt.quizStatus != "InActive"){
                                                isQuizStarted = true;
                                            }
                                            if (data.quizStatus !== "Active") {
                                                isQuizactive = false;
                                            }

                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td> {data.schoolDetails.schoolName} </td>
                                                    <td> {data.schoolDetails.schoolCode} </td>
                                                    <td> {data.quizName} </td>
                                                    <td> {data.quizBatchUsers.length} </td>
                                                    <td>
                                                        {new Date(data.createdDate).toString().slice(0, 15)}
                                                    </td>
                                                    <td> {data.startDate} & {data.startTime} </td>
                                                    <td> {data.endDate} & {data.endTime} </td>
                                                    <td>
                                                        {isQuizStarted ? (
                                                            <h6 style={{ color: "red" }}>Quiz Already Started</h6>
                                                        ) : (
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img
                                                                    style={{ cursor: "pointer" }}
                                                                    className="me-3"
                                                                    src={Edit}
                                                                    width={"15px"}
                                                                    height={"15px"}
                                                                    onClick={() => {
                                                                        // setEditSchl(data.quizName)
                                                                        setShowEditPopup(true);
                                                                        setActionType("Edit");
                                                                        setAccntId(data._lmAccountId);
                                                                        console.log("data", data);
                                                                        setQuizDt({
                                                                            quizId: data.quizId,
                                                                            quizName: data.quizName,
                                                                            questionBankSetId: data.questionBankSetId,
                                                                            quizStartDate: data.startDate,
                                                                            quizStartTime: data.startTime,
                                                                            quizEndDate: data.endDate,
                                                                            quizEndTime: data.endTime,
                                                                            quizBatchUsers: data.quizBatchUsers
                                                                        });
                                                                    }}
                                                                ></img>
                                                                <img
                                                                    style={{ cursor: "pointer" }}
                                                                    className="me-3"
                                                                    src={Delete}
                                                                    width={"15px"}
                                                                    height={"15px"}
                                                                    onClick={() => {
                                                                        setActionType("Delete");
                                                                        console.log("Clicked delete");
                                                                        setShowPopup(true);
                                                                        setQuizDt({
                                                                            quizId: data.quizId
                                                                        });
                                                                    }}
                                                                ></img>
                                                                <Form.Check
                                                                    type="switch"
                                                                    onClick={(e) => {
                                                                        // onQuizStatus(i, data.quizId, e.target.checked)
                                                                        setShowPopup(true);
                                                                        setActionType(e.target.checked);
                                                                        setSelectedQuizId(i);
                                                                        setQuizDt({
                                                                            quizId: data.quizId
                                                                        });
                                                                    }}
                                                                    checked={data.quizStatus === "Active" ? true : false}
                                                                    defaultChecked={data.quizStatus === "Active" ? true : false}
                                                                />
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        "Loading"
                                    )}
                                </tbody>
                            ) : (
                                ""
                            )}
                        </Table>
                        <div>
                            <PopUpModel show={showPopup} cancelClick={setShowPopup} onChangeData={onChangeData} actionTittle={actionType} quizDt={quizDt} />
                        </div>
                        <div>
                            <UpdateQuiz accntId={accntId} show={showEditPopup} cancelClick={setShowEditPopup} actionTittle={actionType} quizDt={quizDt} />
                        </div>
                    </Col>
                )}
            </Row>
        </Container>
    );
};
export default ManageQuizPage;


{
    // selectBtn === "QuizReport" ? "" : <Col style={{ marginTop: "25px" }}>
    //     <Table responsive bordered hover>
    //         <thead>
    //             <tr>
    //                 <th></th>
    //                 <th>School Name</th>
    //                 <th>School Code</th>
    //                 <th>Quiz Name</th>
    //                 <th>Batch Name</th>
    //                 <th>Created Date</th>
    //                 <th>Start Date</th>
    //                 <th>End Date</th>
    //                 <th>Actions</th>
    //             </tr>
    //         </thead>
    //         <tbody>
    //             {
    //                 tableDt != undefined ? tableDt.map((data, i) => {
    //                     let isNewSchool = false;
    //                     if (i >= 1) {
    //                         isNewSchool = true;
    //                     }
    //                     let tempIndex = 0;
    //                     let quizLength = 0;
    //                     return data.quizList.map((dt, index) => {
    //                         if (isNewSchool) {
    //                             quizLength = tableDt[i - 1].quizList.length;
    //                             isNewSchool = false;
    //                             tempIndex = quizLength + index;
    //                         }
    //                         else {
    //                             tempIndex += index;
    //                         }
    //                         return <tr>
    //                             <td>{tempIndex + 1} </td>
    //                             <td>
    //                                 {
    //                                     <div>
    //                                         {data.schoolName}
    //                                     </div>
    //                                 }
    //                             </td>
    //                             <td>
    //                                 {
    //                                     <div>
    //                                         {data.schoolCode}
    //                                     </div>
    //                                 }
    //                             </td>
    //                             <td>
    //                                 {
    //                                     editSchl === dt.quiz_name ? <div>
    //                                         <input type="text" name="quiz_name" onChange={onChangeTxt} placeholder={dt.quizName} className="editTxtBox" />
    //                                     </div> : <div>
    //                                         {dt.quiz_name}
    //                                     </div>
    //                                 }
    //                             </td>
    //                             <td>
    //                                 {
    //                                     editSchl === dt.quiz_name ? <div>
    //                                         <input type="text" name="batch" onChange={onChangeTxt} placeholder={dt.noOfStudents} className="editTxtBox" />
    //                                     </div> : <div>
    //                                         {dt.batch}
    //                                     </div>
    //                                 }
    //                             </td>
    //                             <td>
    //                                 {
    //                                     <div>
    //                                         {dt.created_date.slice(0, 10)}
    //                                     </div>
    //                                 }
    //                             </td>
    //                             <td>
    //                                 {
    //                                     editSchl === dt.quiz_name ? <div>
    //                                         <DatePicker
    //                                             name="startDate"
    //                                             wrapperClassName="customDatePickerWidth"
    //                                             selected={startDate}
    //                                             onInputClick={(date: Date) => {
    //                                                 onDateClick("startDate", date)
    //                                             }}
    //                                             onChange={(date: Date) => { setStartDate(date) }}
    //                                         />
    //                                     </div> : <div>
    //                                         {dt.start_date.slice(0, 10)}
    //                                     </div>
    //                                 }
    //                             </td>
    //                             <td>
    //                                 {
    //                                     editSchl === dt.quiz_name ? <div>
    //                                         <DatePicker
    //                                             name="endDate"
    //                                             wrapperClassName="customDatePickerWidth"
    //                                             selected={endDate}
    //                                             onInputClick={(date: Date) => {
    //                                                 onDateClick("endDate", date)
    //                                             }}
    //                                             onChange={(date: Date) => { setEndDate(date) }}
    //                                         />
    //                                     </div> : <div>
    //                                         {dt.end_date.slice(0, 10)}
    //                                     </div>
    //                                 }
    //                             </td>
    //                             <td>
    //                                 <div className="d-flex align-items-center justify-content-center">
    //                                     {
    //                                         editSchl === dt.quiz_name ? <div>
    //                                             <button onClick={(e) => {
    //                                                 e.preventDefault()
    //                                                 onUpdateCLick(index, data.id)
    //                                             }}>update</button>
    //                                         </div> : <img
    //                                             style={{ cursor: "pointer" }}
    //                                             className="me-3"
    //                                             src={Edit}
    //                                             width={"15px"}
    //                                             height={"15px"}
    //                                             onClick={() => {
    //                                                 setEditSchl(dt.quiz_name)
    //                                             }}
    //                                         ></img>
    //                                     }
    //                                     <img
    //                                         style={{ cursor: "pointer" }}
    //                                         className="me-3"
    //                                         src={Delete}
    //                                         width={"15px"}
    //                                         height={"15px"}
    //                                     ></img>
    //                                     <Form.Check type="switch" onClick={(e) => {
    //                                         onQuizStatus(index, data.id, e.target.checked)
    //                                     }} defaultChecked={dt.quiz_status === "ACTIVATE" ? true : false} />
    //                                 </div>
    //                             </td>
    //                         </tr>
    //                     })
    //                 }) : "Loading"
    //             }
    //         </tbody>
    //     </Table>
    // </Col>
}