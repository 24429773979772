import React, { useEffect, useState } from 'react'
import {
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Box,
    IconButton,
    Modal,
    TextField,
    Button
  } from '@mui/material';
import {Add as AddIcon, Edit as EditIcon, 
    Delete as DeleteIcon, 
    PersonAddAlt as AssignUserIcon,
    Close as CloseIcon, Save, Check } from '@mui/icons-material';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { basic, selectBoxWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';
import DataGridStyle from '../data-table/datagrid_style';
import CustomButton from '../common/button_one';
import CustomButton2 from "../common/button_two";
import MessageSnackbar from '../common/message_snackbar';
import InputTextFieldComponent from '../common/input_text_field';
import DropdownComponent from "../common/dropdown";
import messages from "../../../themes/messages.json";
import CancelButton from '../common/button_cancel';

export default function Users() {
    const __location = useLocation();
    const __navigate = useNavigate();
    const [__customerAccountId, __setCustomerAccountId] = useState("");
    const [__customerUserData, __setCustomerUserDataData] = useState([]);
    const [__filteredCustomerData, __setFilteredCustomerData] = useState([]);
    const [__selectedRole, __setSelectedRole] = useState("All");
    const [__singleUserModalOpen, __setSingleUserModalOpen] = useState(false);

    const [__snackbarOpen, __setSnackbarOpen] = useState(false);
    const [__snackbarMessage, __setSnackbarMessage] = useState("");
    const [__snackbarVariant, __setSnackbarVariant] = useState("success");

    const __userRoleOptions = [
        //{ value: "", label: "Select Role" },
        { value: "Teacher", label: "Teacher" },
        { value: "Student", label: "Student" },
        { value: "School Account", label: "School Account" }
    ];

    const __defaultFields = {
      userName:"",
      userEmail:"",
      userRole:"Teacher",
      grade:[],
      dob:"",
      phonenumber:"",
      customerAccountId:""
  };

    const [__userDetails, __setUserDetails] = useState(__defaultFields);

    const [__grades, __setGrades] = useState([]);
    const [__currentGrade, __setCurrentGrade] = useState("");
    const [__isSaveEnabled, __setIsSaveEnabled] = useState(false);
    const [__errorsMsg, __setErrorsMsg] = useState({});

    const __fetchCustomerUsersData = async (aCustomerAccountId) => {
      try{
        const _customerUsersData = await axios.post("/lmAccountsUser/getUserData",{ _lmAccountId: aCustomerAccountId });
        __setCustomerUserDataData(_customerUsersData.data);
        __setFilteredCustomerData(_customerUsersData.data);
      }catch(aError){
          console.log("error fetch data", aError);
      }      
    }

    useEffect(() => {
      const _getCurrentCustomerId = localStorage.getItem("_accountId");
      if(_getCurrentCustomerId){
        __setCustomerAccountId(_getCurrentCustomerId);
        __setUserDetails((prev) => ({
          ...prev,
          customerAccountId: __customerAccountId
        }));
        __fetchCustomerUsersData(__customerAccountId);
      }
    }, [__customerAccountId]);


    const __handleSnackbarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      __setSnackbarOpen(false);
      __closeSingleUserModal();
    };

    const __triggerSnackbar = (message, variant) => {
        __setSnackbarMessage(message);
        __setSnackbarVariant(variant);
        __setSnackbarOpen(true);
    };

    const __handleUserRoleFilter = (event) => {
      const _role = event.target.value;
      __setSelectedRole(_role);
      if(_role == "All"){
        __setFilteredCustomerData(__customerUserData);
      }else{
        __setFilteredCustomerData(__customerUserData.filter(aUserData => aUserData.userRole.toLowerCase() ===_role.toLowerCase()));
      }
    }

    const __openSingleUserModal = () => {
      __setSingleUserModalOpen(true)
    }

    const __closeSingleUserModal = () => {
      __setSingleUserModalOpen(false)
    }

    const handleAddGrade = () => {

      let __updatedGrades = [];

      //if (__currentGrade.trim() !== "" && !__grades.includes(__currentGrade)) {
      if (
        __currentGrade.trim() !== "" &&
        !__grades.some((grade) => grade.toLowerCase() === __currentGrade.trim().toLowerCase())
      ) {
          const _currentGrade = __currentGrade.trim();
          __updatedGrades = [...__grades, _currentGrade];
          __setGrades(__updatedGrades);
          __setCurrentGrade(""); 

          __setUserDetails((prev) => ({
          ...prev,
          grade: __updatedGrades
          }));
      }

      console.log(__updatedGrades);

      if (__updatedGrades.length > 0) {
        __setErrorsMsg((prev) => ({
          ...prev,
          grade: null
          }));
      }
    };

    const handleRemoveGrade = (aGrade) => {
        const _updatedGrades = __grades.filter((grade) => grade !== aGrade);
        __setGrades(_updatedGrades);

        __setUserDetails((prev) => ({
          ...prev,
          grade: __grades
        }));

        console.log(_updatedGrades);

        if (__grades.length > 0) {
          __setErrorsMsg((prev) => ({
            ...prev,
            grade: null
            }));          
        }

    };

    const __inputValueChange = (aField, aValue) => {
        __setUserDetails(aPrev => ({
        ...aPrev,
        [aField]: aValue
        }))
    }

    const __addSingleUser = async () => {
      const _emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const _phoneRegex = /^[0-9]{10}$/; // for 10-digit phone numbers

      let __hasError = false;
      if(!__userDetails['userName']){
          __setErrorsMsg((aPrevError) => ({
            ...aPrevError,
            userName: "Username is required.",
          }));
          __hasError = true;
      }else{
          __setErrorsMsg((prevErrors) => {
            const { userName, ...restErrors } = prevErrors;
            return restErrors;
          });
      }

      if(!__userDetails['userEmail']){
          __setErrorsMsg((aPrevError) => ({
            ...aPrevError,
            userEmail: "Email is required.",
          }));
          __hasError = true;
      }else if(!_emailRegex.test(__userDetails.userEmail)){
          __setErrorsMsg((aPrevError) => ({
            ...aPrevError,
            userEmail: "Please enter a valid email address.",
          }));
          __hasError = true;
      }else {
          __setErrorsMsg((prevErrors) => {
              const { userEmail, ...restErrors } = prevErrors;
              return restErrors;
          });
      }

      if(!__userDetails['userRole']){
          __setErrorsMsg((aPrevError) => ({
            ...aPrevError,
            userRole: "Role is required.",
          }));
          __hasError = true;
      }else {
          __setErrorsMsg((prevErrors) => {
              const { userRole, ...restErrors } = prevErrors;
              return restErrors;
          });
      }

      if (__grades.length === 0) {
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            grade: "At least one grade is required."
          }));
          __hasError = true;
      }else{
          __setErrorsMsg((prevErrors) => {
            const { grade, ...restErrors } = prevErrors; 
            return restErrors;
          });
          __hasError = false;
      }

      if (!__userDetails["dob"]) {
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            dob: "Please select the date of birth."
          }));
          __hasError = true;
      }else {
          __setErrorsMsg((prevErrors) => {
              const { dob, ...restErrors } = prevErrors;
              return restErrors;
          });
      }

      if (!__userDetails["phonenumber"]) {
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            phonenumber: "Phone number is required."
          }));
          __hasError = true;
      }else if(!_phoneRegex.test(__userDetails.phonenumber)){
          __setErrorsMsg((prevErrors) => ({
            ...prevErrors,
            phonenumber: "Please enter a valid 10-digit phone number."
          }));
          __hasError = true;
      }else {
          __setErrorsMsg((prevErrors) => {
              const { phonenumber, ...restErrors } = prevErrors;
              return restErrors;
          });
      }

      if (__hasError) return;

      try {

        console.log(__userDetails);

        const _addUser = await axios.post("/lmAccountsUser/users", __userDetails);

        console.log(_addUser);

        if(_addUser.data.status == "success"){          
          __fetchCustomerUsersData(__customerAccountId);
          __closeSingleUserModal();
          __setSnackbarOpen(true);
          __triggerSnackbar("Successfully added the user.","success"); 
          __setUserDetails((prev) => ({
              ...prev,
              userName:"",
              userEmail:"",
              userRole:"Teacher",
              grade:[],
              dob:"",
              phonenumber:"",
          }));
          console.log(__userDetails);
          __setErrorsMsg({});
          __setGrades([]);
        }else{
          __closeSingleUserModal();
          __setSnackbarOpen(true);
          __triggerSnackbar("Failed to add user.","error"); 
          
        }

      } catch (err) {
        console.log("Error", err);
      }

      
      //console.log(schoolData);

    }
    
    return (
        <div>

          <Modal
            open={__singleUserModalOpen}
            onClose={__closeSingleUserModal}
            visible={true}
            //title={"Add New User"}
          >
            <Box
              sx={{
                display: 'flex', 
                justifyContent: 'center', // Centers horizontally
                alignItems: 'top', // Centers vertically
                width: '100vw', // Full viewport width
                //height: '100vh', // Full viewport height
                position: 'fixed',
                top: 20,
                left: 0,
              }}
            >
                <Box
                  sx={{
                    width: 400,
                    height: "auto",
                    backgroundColor: basic.colorWhite,
                    //margin: 'auto',
                    padding: 0,
                    position: 'relative',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    borderRadius: "5px",
                    padding:"20px"
                  }}
                >
                    <div 
                      title='Close' 
                      style={{
                        background: basic.redLightColor,
                        width: "27px",
                        height: "27px",
                        position: "absolute",
                        right: "0px",
                        top: "0px",
                        borderRadius:"0px 5px 0px 0px",
                        textAlign:"center",
                        cursor:"pointer"
                      }}
                    >
                        <CloseIcon
                          title="Close"
                          style={{ color:basic.colorWhite}}
                          fontSize='20'
                          onClick={__closeSingleUserModal}
                         >
                            X
                        </CloseIcon>                      
                    </div>
                    <Typography style={{fontSize:basic.headingFontSize,fontFamily:basic.fontFamily, marginTop:"0px", textAlign:"left", marginLeft:"10px", marginBottom:"0px", width:"100%", display:"block"}} variant="h4"><strong>Add User</strong></Typography>
                    <Grid container spacing={2} style={{ padding: 5, marginTop: 0 }}>
                      <Grid item md={12}>

                        <InputTextFieldComponent 
                          required 
                          label="User Name"  
                          value={__userDetails.userName}
                          onChange={(e) => __inputValueChange("userName",e.target.value)}
                          error={Boolean(__errorsMsg.userName)}
                          helperText={__errorsMsg.userName || ""}
                          style={{ marginBottom:"12px"}}
                        />

                        { /* User Name */}
                        <InputTextFieldComponent 
                          required 
                          label="User Email"  
                          value={__userDetails.userEmail}
                          onChange={(e) => __inputValueChange("userEmail",e.target.value)}
                          error={Boolean(__errorsMsg.userEmail)}
                          helperText={__errorsMsg.userEmail || ""}
                          style={{ marginBottom:"12px"}}
                        />

                        { /* User Role */}
                        <DropdownComponent
                          label="User Role"
                          value={__userDetails.userRole}
                          onChange={(e) => __inputValueChange("userRole",e.target.value)}
                          options={__userRoleOptions}
                          error={Boolean(__errorsMsg.userRole)}
                          helperText={__errorsMsg.userRole || ""}
                          required
                          sx={{  mt:"5px",mb:"4px" }}
                          style={{ marginBottom:"0px"}}
                        />

                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1, mb:1 }}>

                              { /* User Grade */}
                              <InputTextFieldComponent 
                                required 
                                label="User Grade"  
                                value={__currentGrade}
                                onChange={(e) => __setCurrentGrade(e.target.value)}
                                error={Boolean(__errorsMsg.grade)}
                                helperText={__errorsMsg.grade || ""}
                                style={{ width: "86%", marginBottom:"12px", fontFamily:basic.fontFamily }}
                              />
                              
                              <Button 
                                disabled={!__currentGrade.trim().length}
                                sx={{
                                    minWidth:"40px",
                                    mt: 0,
                                    height:"37px",
                                    backgroundColor:basic.logoGreyColor,
                                    padding:"0px",
                                    "&:hover": {
                                      bgcolor: basic.greenColor,
                                      color: basic.colorWhite,
                                      iconcolor: basic.greenColor,
                                    }
                                }} 
                                onClick={handleAddGrade} title='Add Grade' variant="contained">
                                <AddIcon fontSize='30' />
                              </Button>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                          {__grades.length > 0 && (
                            <Box sty>
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0, mb: "10px", mt:"-10px" }}>
                                {__grades.map((grade, index) => (
                                  <Button
                                    key={index}
                                    variant="outlined"
                                    style={{                                       
                                      fontSize:basic.contentFontSize, 
                                      marginRight:"3px", 
                                      minWidth:"auto", 
                                      padding:"2px 8px",
                                      background:basic.greenColor,
                                      border:"1px solid" +basic.greenColor,
                                      color:basic.colorWhite,     
                                      lineHeight:"auto",
                                      fontWeight:"normal",      
                                      marginBottom:"5px",
                                      textTransform:"none"                           
                                    }}
                                    sx={{
                                      fontFamily:basic.fontFamily
                                    }}
                                    title={grade}
                                    onClick={() => handleRemoveGrade(grade)}                                    
                                  >
                                    {grade} &times;
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                          )}
                          </Grid>

                          { /* Date of birth */}
                          <InputTextFieldComponent 
                            required 
                            label="Date of birth"  
                            type="date"
                            value={__userDetails.dob}
                            onChange={(e) => __inputValueChange("dob",e.target.value)}
                            error={Boolean(__errorsMsg.dob)}
                            helperText={__errorsMsg.dob || ""}
                            style={{marginBottom:"12px"}}
                            InputLabelProps={{
                              shrink: true,                              
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}  
                          />

                          { /* Phone Number */}
                          <InputTextFieldComponent 
                            required 
                            label="Phone Number"  
                            value={__userDetails.phonenumber}
                            onChange={(e) => __inputValueChange("phonenumber",e.target.value)}
                            error={Boolean(__errorsMsg.phonenumber)}
                            helperText={__errorsMsg.phonenumber || ""}
                          />

                          
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ padding: "0px 0px 0px 5px", marginTop: 0 }}>
                      <Grid item md={12}>
                        <div style={{ display: "flex", justifyContent:"flex-end", alignContent:"right",  marginTop: 0, alignItems:"right" }}>    
                          
                          <CancelButton onClick={__closeSingleUserModal} aButtonText="Cancel" />
                          <CustomButton2 
                            aria-label="Save"
                            onClick={__addSingleUser}
                            style={{
                              marginRight: "5px",
                            }}
                          >
                            <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
                            <div style={{ marginLeft: 5 }}>Save</div>
                          </CustomButton2>
                          </div>
                        </Grid>
                    </Grid>                   
                </Box>
            </Box>
           
          </Modal>
          <Container fluid={true} style={{ padding:"0px"}}>
          
              <Grid container spacing={0}>
                  <Typography style={{fontSize:basic.headingFontSize,fontFamily:basic.fontFamily, marginTop:"0px", textAlign:"left", marginLeft:"0px", marginBottom:"20px", width:"100%", display:"block"}} variant="h4"><strong>Users</strong></Typography>
              </Grid>
              <Grid container spacing={0}>
                  <Grid item xs={12} sm={12}>
                      <Box
                      sx={{
                      height: "auto",
                      width: "100%",
                      ul: {
                      bgcolor: "#f6f6f6",
                      color: "#ffffff",
                      },
                      }}
                      >

                      <div style={{ display: "flex", position:"absolute", marginTop: 10,zIndex:"1000" }}>                            
                          <CustomButton2
                          aria-label="Add Users"
                          onClick={__openSingleUserModal}
                          >
                              <AddIcon fontSize={basic.headingFontSize} style={{ marginTop:-1 }} />
                              <div style={{ marginLeft: 5 }}>Add Users</div>
                          </CustomButton2>
                          <CustomButton aria-label="Import Users">
                              <AssignUserIcon fontSize={basic.headingFontSize}/>
                              <div style={{ marginLeft: 10 }}>Import Users</div>
                          </CustomButton>
                          
                      </div>

                      <FormControl style={{ display: "flex",  position:"absolute", marginTop: 10,zIndex:"1000", right:"225px" }} sx={{ minWidth: 170, mb: 2, height:15 }}>
                            <InputLabel id="role-filter-label" 
                              sx={{ 
                                fontFamily:basic.fontFamily, 
                                fontSize:basic.inputFieldDefaultFontSize, 
                                height:"35px", 
                                marginTop:"-8px", 
                                "&.Mui-focused": {
                                    marginTop:"3px !important",               
                                },
                                "&.MuiInputLabel-shrink": {
                                    marginTop:"3px !important",
                                    fontFamily:basic.fontFamily,
                                    fontSize:basic.labelFontSize,
                                    transform: "translate(13px, -12px) scale(1) !important",
                                    WebkitTransform: "translate(13px, -12px) scale(1) !important",
                                    MozTransform: "translate(13px, -12px) scale(1) !important",
                                    MsTransform: "translate(13px, -12px) scale(1) !important",
                                    OTransform: "translate(13px, -12px) scale(1) !important",
                                    color:basic.colorBlack,
                                },
                                "&.MuiOutlinedInput-root fieldset": {     
                                    borderColor: basic.logoGreyColor +" !important"                                   
                                }                                 
                            }}>
                                  Filter by Role
                            </InputLabel>
                            <Select
                              labelId='role-filter-label'
                              value={__selectedRole}
                              onChange={__handleUserRoleFilter}
                              label="Filter by label"
                              sx={selectBoxWithLabel}
                            >
                              <MenuItem 
                              value="All"
                              sx={{ 
                                  fontFamily:basic.fontFamily, 
                                  fontSize:basic.inputFieldDefaultFontSize
                              }}>
                                All
                              </MenuItem>
                              <MenuItem 
                              value="Teacher"
                              sx={{ 
                                fontFamily:basic.fontFamily, 
                                fontSize:basic.inputFieldDefaultFontSize
                              }}>
                                Teacher
                              </MenuItem>
                              <MenuItem value="Student"
                              sx={{ 
                                fontFamily:basic.fontFamily, 
                                fontSize:basic.inputFieldDefaultFontSize
                              }}>
                                Student
                              </MenuItem>
                              <MenuItem value="School Account"
                              sx={{ 
                                fontFamily:basic.fontFamily, 
                                fontSize:basic.inputFieldDefaultFontSize
                              }}>
                                School Account
                                </MenuItem>
                            </Select>
                      </FormControl>
                      

                      <DataGridStyle
                          rows={__filteredCustomerData}
                          columns={[
                          {
                              field: "regNum",
                              headerName: "Reg. No",
                              maxWidth:125,
                              flex: 1,
                              headerAlign: "left",
                          },
                          {
                              field: "userName",
                              headerName: "Name",
                              maxWidth:120,
                              flex: 1,
                              headerAlign: "left",
                          },
                          {
                              field: "userEmail",
                              headerName: "Email",
                              flex: 1.4,
                              headerAlign: "left",
                          },
                          {
                            field: "userRole",
                            headerName: "Role",
                            maxWidth:80,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "grade",
                            headerName: "Grade",
                            maxWidth:90,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "dob",
                            headerName: "DOB",
                            maxWidth:90,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "pin",
                            headerName: "PIN",
                            maxWidth:80,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "OTP",
                            headerName: "OTP",
                            maxWidth:80,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "phoneNum",
                            headerName: "Phone No",
                            maxWidth:110,
                            flex: 1,
                            headerAlign: "left",
                          },
                          {
                            field: "actions",
                            headerName: "Actions",
                            flex: 1,
                            maxWidth: 90,
                            headerAlign: "left",
                            disableExport: true,
                            renderCell: (params) => (
                            <Box>
                                    <IconButton
                                      disabled
                                      aria-label="Edit"
                                    //onClick={() => __handleEditSubscription(params.row.id)}
                                    >
                                        <EditIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Edit' />
                                    </IconButton>                                

                                <IconButton
                                    disabled
                                    aria-label="Delete"
                                    //onClick={() => __handleDeleteSubscriptionOpen(params.row.id)}
                                    >
                                    <DeleteIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Delete' />
                                </IconButton>
                                
                            </Box>
                            ),
                        },                                  
                          ]}
                          disableSelectionOnClick
                          autoHeight={true}
                          export={false}
                          checkboxSelection={false}
                      />
                      </Box>
                  </Grid>
              </Grid>
          </Container>

          <MessageSnackbar
                message={__snackbarMessage}
                variant={__snackbarVariant}
                open={__snackbarOpen}
                onClose={__handleSnackbarClose}
            />
        </div >
    );

}