import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import PrimaryTextBox from '../../CmnCompnts/TextBox'
import SubscriptionSteps from './CreateSubscription';

export default function Subscription(props) {
    const [previousSubscriptions, setPreviousSubscriptions] = useState();
    const [subBtnClicked, setSubBtnClicked] = useState(false);

    const navigate = useNavigate()

    const [allPrevSubscriptions, setAllPrevSubscriptions] = useState();

    useEffect(() => {
        setAllPrevSubscriptions(props.allPrevSubscriptions)

        // axios.get("/license/getAllSubscriptions")
        //     .then((res) => {
        //         setAllPrevSubscriptions(res.data)
        //         console.log("Getting all subscriptions", res.data);
        //     })
        //     .catch((err) => {
        //         console.log("Error came while fetching subscriptions");
        //     })
    }, [])


    return (
        <section>
            <div>
                <h3>Existing Subscriptions</h3>
                <div>
                    {
                        allPrevSubscriptions ? <Row>
                            <Col xs={12}>
                                <div>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <td>License No.</td>
                                                <td>Category</td>
                                                <td>License Type</td>
                                                <td>Total Licenses</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allPrevSubscriptions.map((dt, i) => {
                                                    return <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{dt.category}</td>
                                                        <td>{dt.license_type}</td>
                                                        <td>{dt.total_units}</td>
                                                        <td>
                                                            <button style={{ backgroundColor: "white", fontSize: "14px", border: "1px solid lightgrey" }} onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate('/dashboard/assignStudentsToLicense', {
                                                                    state:
                                                                    {
                                                                        LicenseData : dt
                                                                    }
                                                                })
                                                            }}>Add Students</button>
                                                            <button style={{ backgroundColor: "white", fontSize: "14px", border: "1px solid lightgrey" }}>Delete</button>
                                                            <button style={{ backgroundColor: "white", fontSize: "14px", border: "1px solid lightgrey" }}>Edit</button>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                            {/* {
                                allPrevSubscriptions.map((dt, i) => {
                                    return <Col xs={4} className='mt-3'>
                                        <div style={{ border: "1px solid lightgrey", padding: "15px", borderRadius: "15px" }}>
                                            <h5 >
                                                <b>License {i + 1}</b>
                                            </h5>
                                            <h6 style={{ fontSize: "14px" }}>
                                                Category : {dt.category}
                                            </h6>
                                            <h6 style={{ fontSize: "14px" }}>
                                                Total Units: {dt.total_units}
                                            </h6>
                                            <h6 style={{ fontSize: "14px" }}>
                                                License Type: {dt.license_type}
                                            </h6>
                                            <h6 style={{ fontSize: "14px" }}>
                                                License Alloted : NO
                                            </h6>
                                            
                                            <div>
                                                <button>
                                                    Assign Students
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                })
                            } */}
                        </Row> : ""

                    }
                </div>
            </div>
            <div className='mt-4'>
                <h4><b>To Add New Subscription</b></h4>
            </div>
            <Row >
                <Col xs={12}>
                    <div style={{ minHeight: "300px" }}>
                        <Row>
                            {
                                previousSubscriptions ? <Col xs={2}>
                                    Here previous subscription will show
                                </Col> :
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={12}>
                                                <div className='mt-2' style={{ position: "relative" }}>
                                                    <div>
                                                        {
                                                            subBtnClicked ? "" : <button className='prim-btn p-2' style={{ borderRadius: "5px" }} onClick={(e) => {
                                                                e.preventDefault()
                                                                navigate('/dashboard/createSubscription')
                                                                // setSubBtnClicked(true)
                                                            }}>
                                                                Create New Subscription
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                {
                                                    subBtnClicked ? <div>
                                                        <SubscriptionSteps />
                                                    </div> : ""
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                            }
                        </Row>
                    </div>
                </Col>
            </Row>
        </section>
    )
}
