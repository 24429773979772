import { Button as ButtonValue } from "@mui/material";
import React from "react";
import { basic } from '../../../themes/basic';
const CustomButton = (props) => {
  return (
    <ButtonValue
      variant="contained"
      sx={{
        marginRight: 2,
        height: "35px",
        fontSize: basic.buttonFontSize,
        borderRadius: "8px",
        //filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
        color: basic.colorWhite,
        fontWeight: 500,
        minWidth: "120px",
        padding:"6px 10px",
        textTransform: "none",
        fontFamily:basic.fontFamily,
        bgcolor: basic.logoGreyColor,
        ":hover": {
          bgcolor: basic.greenColor,
          color: basic.colorWhite,
          iconcolor: basic.greenColor,
        },
      }}
      {...props}
    >
      {props.children}
    </ButtonValue>
  );
};

export default CustomButton;
