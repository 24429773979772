import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavBar from "../navBar";
import { Route, useLocation } from 'react-router-dom';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import VerifyAccount from './verifyAccount';

function SchoolVerifications(props) {
    const [usersData, SetUsersData] = useState([])
    const [showComp, SetShowComp] = useState(false)
    const [schlDetail, SetSchlDetail] = useState("");

    useEffect(() => {
        axios.get("/admin/pendngVerfn")
            .then((res) => {
                console.log("Data Recieved ", res.data.allData)
                SetUsersData(res.data.allData)
            })
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'User', headerName: 'First name', width: 130 },
        { field: 'email', headerName: 'Email-ID', width: 250 },
        { field: 'school', headerName: 'School', width: 200 },
        { field: 'shcl_code', headerName: 'School Code', width: 150 },
        { field: 'verification', headerName: 'Verification', width: 200 },
    ];

    const rows = usersData.map((dt, key) => {
        // return { id: dt._id, User: dt.user_name,email: dt.user_email, school: "svs"}
        let school_name = "";
        let schoolVerification = "";
        let school_code = "";
        dt.allSchoolDt.map((dt2, indx) => {
            // if (dt.schoolDetails != undefined) {
            school_name = dt2.schoolName;
            schoolVerification = dt2.schoolVerified;
            school_code = dt2.schoolCode;
            // return { id: (indx + 1), User: dt.user_name, email: dt.user_email, school: school_name, shcl_code: school_code, verification: schoolVerification }
            // return { id: (key + 1), User: " dt.user_name", email: "dt.user_email", school: "school_name", shcl_code: "school_code", verification: "schoolVerification" }
            // }
        })
        // })
        return { id: (key + 1), User: dt.user_name, email: dt.user_email, school: school_name, shcl_code: school_code, verification: schoolVerification }
    });

    // const rows = [
    //     { id: 1, lastName: 'r', firstName: 'shashank', email: "example@gmail.com", role: "admin" },
    //     { id: 2, lastName: 'pr', firstName: 'aishwarya', email: "example@gmail.com", role: "student" },
    //     { id: 3, lastName: '-', firstName: 'manish', email: "example@gmail.com", role: "student" },
    //     { id: 4, lastName: 's', firstName: 'santhosh', email: "example@gmail.com", role: "teacher" },
    //     { id: 5, lastName: 'khan', firstName: 'salman', email: "example@gmail.com", role: "teacher" },
    //     { id: 6, lastName: 'matters', firstName: 'learning', email: "example@gmail.com", role: "student" },
    //     { id: 7, lastName: 's', firstName: 'naveen', email: "example@gmail.com", role: "student" },
    //     { id: 8, lastName: 'doe', firstName: 'john', email: "example@gmail.com", role: "student" },
    // ];

    const rowClickd = (e) => {
        console.log("Row data", e.row)
        SetSchlDetail(e.row)
        SetShowComp(true)
    }

    const cancelClicked = () => {
        SetShowComp(false)
    }

    return (
        <Container fluid={true}>
            {/* <NavBar /> */}
            <Row style={{ marginTop: "30px" }}>
                <Col md={8} style={{ margin: "0px auto" }}>
                    <h4>Pending Verifications</h4>
                </Col>
            </Row>
            <Row style={{ marginTop: "30px" }}>
                <Col md={8} style={{ margin: "0px auto", minHeight: "400px", maxHeight: "500px" }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        // checkboxSelection
                        // autoHeight={true}
                        onRowClick={rowClickd}
                    />
                </Col>
            </Row>
            {/* {
                usersData != null ? usersData.map((a, key) => {

                    console.log("zzzzzzzzzzzzzz", a._id);
                }) : console.log("ddddddddddd")

            } */}
            <Row>
                <Col xs={12}>
                    {
                        showComp ? <VerifyAccount schlDetail={schlDetail} cancelClked={cancelClicked} /> : ""
                    }
                </Col>
            </Row>
        </Container>
    )
}


export default SchoolVerifications;