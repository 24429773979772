import Pagination from 'react-bootstrap/Pagination';

import React, { useState } from 'react'

export default function PaginationCompnt(props) {
    const { currentPage, setCurrentPage, totalData, rowsPerPage } = props;
    const totalPages = Math.ceil(totalData / rowsPerPage);

    const pageItems = [];

    for (let number = 1; number <= totalPages; number++) {
        pageItems.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => setCurrentPage(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <div>
            <Pagination>{pageItems}</Pagination>
        </div>
    );
}
