import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Image,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import NavBar from "../navBar";
import studentImg from "../Assets/students.svg";
import teachersImg from "../Assets/teachers.svg";
import adminsImg from "../Assets/admins.svg";
import subscriptionsImg from "../Assets/subscriptions.svg";
import { Grid, Container as MateriaContainer } from "@mui/material";
import axios, { Axios } from "axios";
import Profile_Complete from "../ProfileCompletion/Profile_Complete";
import { DataGrid } from "@mui/x-data-grid";
import AllProducts from "./AllProducts";
import editBtnImg from "../Assets/edit.svg";
import deleteBtnImg from "../Assets/delete.svg";
import admins_e from "../Assets/admins-e.svg";
import admins_d from "../Assets/admins-d.svg";
import students_e from "../Assets/students-e.svg";
import students_d from "../Assets/students-d.svg";
import teachers_e from "../Assets/teachers-e.svg";
import teachers_d from "../Assets/teachers-d.svg";
import subscriptions_e from "../Assets/subscriptions-e.svg";
import subscriptions_d from "../Assets/subscriptions-d.svg";
import { UserContext } from "../Context/Reducer";
import Subscription from "./Subscription/Subscription";

function UserDashboard(props) {
    // const [curntPage, setCrntPage] = useState("school");
    const { state, dispatch } = useContext(UserContext); //context dt
    const [allUsers, setallUsers] = useState({});
    const [selectedUser, setSelectedUser] = useState("null");
    const [fullScreen, setFullScreen] = useState(true);
    const [show, setShow] = useState(false);
    const [isSelected, setIsSelected] = useState("none");
    const [adminsData, setAdminsData] = useState([]);
    const [studentsData, setStudentsData] = useState([]);
    const [teachersData, setTeachersData] = useState([]);
    const [allPrevSubscriptions, setAllPrevSubscriptions] = useState();

    function handleShow(breakpoint) {
        setFullScreen(breakpoint);
        setShow(true);
    }

    useEffect(() => {
        const _lmAccountId = localStorage.getItem("lmAccountId");
        console.log("_lmAccountId", _lmAccountId);

        if (_lmAccountId) {

            axios.get("/license/getAllSubscriptions")
                .then((res) => {
                    setAllPrevSubscriptions(res.data)
                    console.log("Getting all subscriptions", res.data);
                })
                .catch((err) => {
                    console.log("Error came while fetching subscriptions");
                })

            axios
                .post("/lmAccountsUser/getUserData", { _lmAccountId })
                .then((res, err) => {
                    dispatch({
                        type: "Get_Data",
                        payload: res.data,
                    });
                    console.log("Got data from _lmAccountId", res.data);
                    setallUsers(res.data);
                    filterRoles(res.data)
                })
                .catch((err) => {
                    console.log("couldn't get the data");
                });
        }
    }, []);

    const filterRoles = (dt) => {
        let studentsData = []
        let adminsData = []
        let teachersData = []
        if (dt) {
            dt.map((dt, i) => {
                if (dt.userRole === "student") {
                    studentsData.push(dt)
                }
                if (dt.userRole === "teacher") {
                    teachersData.push(dt)
                }
                if (dt.userRole === "admin") {
                    adminsData.push(dt)
                }
            })
        }
        console.log("studentsData:;;;;;;;;;", studentsData);
        setStudentsData(studentsData)
        setAdminsData(adminsData)
        setTeachersData(teachersData)
    }

    const onSelectRole = (e) => {
        console.log("Selected ", e);
        setSelectedUser(e);
    };

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "firstName", headerName: "First name" },
        { field: "lastName", headerName: "Last name" },
        { field: "email", headerName: "Email-ID", width: 175 },
        {
            field: "role",
            headerName: "Role",
            width: 100,
        },
        {
            field: "pin",
            headerName: "PIN",
            width: 75,
        },
        {
            field: "alexa",
            headerName: "Linked to alexa",
            width: 125,
        },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: () => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={editBtnImg} width="30px" height={"20px"} />
                        <img
                            src={deleteBtnImg}
                            width="30px"
                            height={"25px"}
                            style={{ marginLeft: "8px" }}
                        />
                        {/* <Button variant="light" style={{ marginRight: "10px" }}>Edit</Button>
                    <Button variant="danger">Delete</Button> */}
                    </div>
                );
            },
        },
    ];

    const AllStudents = (e) => {
        return (
            studentsData.length > 0 ? <Table responsive bordered hover style={{ backgroundColor: "white" }}>
                <thead>
                    <tr>
                        <th>Reg No.</th>
                        <th>User Name</th>
                        <th>Email-ID</th>
                        <th>OTP </th>
                        <th>Role</th>
                        <th>Grade</th>
                        <th>DOB</th>
                        <th>Phone No.   </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        studentsData.length > 0 ? studentsData.map((dt, i) => {
                            return <tr>
                                <td>{dt.regNum}</td>
                                <td>{dt.userName}</td>
                                <td>{dt.userEmail}</td>
                                <td>{dt.OTP}</td>
                                <td>{dt.userRole}</td>
                                <td>{dt.grade}</td>
                                <td>{dt.dob}</td>
                                <td>{dt.phoneNum}</td>
                            </tr>
                        }) : "Loading"
                    }
                </tbody>
            </Table> : "No Students Added"
        );
    };

    const AllTeachers = (e) => {
        return (
            teachersData.length > 0 ? <Table responsive bordered hover style={{ backgroundColor: "white" }}>
                <thead>
                    <tr>
                        <th>Reg No.</th>
                        <th>User Name</th>
                        <th>Email-ID</th>
                        <th>OTP</th>
                        <th>Role</th>
                        <th>Grade</th>
                        <th>DOB</th>
                        <th>Phone No.   </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        teachersData.length > 0 ? teachersData.map((dt, i) => {
                            return <tr>
                                <td>{dt.regNum}</td>
                                <td>{dt.userName}</td>
                                <td>{dt.userEmail}</td>
                                <td>{dt.OTP}</td>
                                <td>{dt.userRole}</td>
                                <td>{dt.grade}</td>
                                <td>{dt.dob}</td>
                                <td>{dt.phoneNum}</td>
                            </tr>
                        }) : "Loading"
                    }
                </tbody>
            </Table> : "No Teachers Added"
        );
    };

    const AllAdmins = (e) => {
        // const studentRow = allUsers.users_list.admins.map((dt, key) => {
        //     return {
        //         id: key + 1,
        //         firstName: dt.firstname,
        //         lastName: dt.lastname,
        //         email: dt.email,
        //         role: dt.role,
        //         pin: dt.key,
        //         alexa: dt.isAlexaSkillLinked,
        //     };
        // });
        // return (
        //     <DataGrid
        //         rows={studentRow}
        //         columns={columns}
        //         pageSize={5}
        //         rowsPerPageOptions={[5]}
        //     />
        // );
        return (
            adminsData.length > 0 ? <Table responsive bordered hover style={{ backgroundColor: "white" }}>
                <thead>
                    <tr>
                        <th>Reg No.</th>
                        <th>User Name</th>
                        <th>Email-ID</th>
                        <th>OTP</th>
                        <th>Role</th>
                        <th>Grade</th>
                        <th>DOB</th>
                        <th>Phone No.   </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        adminsData.length > 0 ? adminsData.map((dt, i) => {
                            return <tr>
                                <td>{dt.regNum}</td>
                                <td>{dt.userName}</td>
                                <td>{dt.userEmail}</td>
                                <td>{dt.OTP}</td>
                                <td>{dt.userRole}</td>
                                <td>{dt.grade}</td>
                                <td>{dt.dob}</td>
                                <td>{dt.phoneNum}</td>
                            </tr>
                        }) : "Loading"
                    }
                </tbody>
            </Table> : "No Admins Added"
        );
    };

    const loadSubscription = (e) => {
        e.preventDefault();
        axios.get("/getSubscriptions").then((res) => {
            console.log("IIIIIIIIII", res.data);
        });
    };

    const switchUsers = (e) => {
        switch (e) {
            case "students":
                return (
                    <Grid item style={{ width: "100%", height: "350px" }}>
                        <AllStudents />
                    </Grid>
                );
            case "teachers":
                return (
                    <Grid item style={{ width: "100%", height: "350px" }}>
                        <AllTeachers />
                    </Grid>
                );
            case "admins":
                return (
                    <Grid item style={{ width: "100%", height: "350px" }}>
                        <AllAdmins />
                    </Grid>
                );
            case "subscriptions":
                return <Subscription allPrevSubscriptions={allPrevSubscriptions} />
        }
    };

    return (
        <section>
            <Row>
                <Col lg={"12"} style={{ height: "90vh" }}>
                    <Row className="mt-4">
                        <Col xs={3}>
                            <Card
                                onClick={() => onSelectRole("students")}
                                className={`w-100 d-flex flex-row  align-items-center ps-4 ${selectedUser === "students" ? "slctd-btn" : "dflt-Btn"}`}
                            >
                                <Image src={selectedUser === "students" ? students_e : students_d} width={"60px"} />
                                <Card.Body className="ps-4">
                                    <h2>
                                        <b>
                                            {studentsData.length}
                                        </b>
                                    </h2>
                                    <h5>Students</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Card
                                onClick={() => onSelectRole("teachers")}
                                className={`w-100 d-flex flex-row align-items-center ps-4 ${selectedUser === "teachers" ? "slctd-btn" : "dflt-Btn"}`}
                            >
                                <Image src={selectedUser === "teachers" ? teachers_e : teachers_d} height={"60px"} />
                                <Card.Body className="ps-4">
                                    <h2>
                                        <b>
                                            {teachersData.length}
                                        </b>
                                    </h2>
                                    <h5>Teachers</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Card
                                onClick={() => onSelectRole("admins")}
                                className={`w-100 d-flex flex-row align-items-center ps-4 ${selectedUser === "admins" ? "slctd-btn" : "dflt-Btn"}`}
                            >
                                <Image src={selectedUser === "admins" ? admins_e : admins_d} width={"60px"} />
                                <Card.Body className="ps-4">
                                    <h2>
                                        <b>
                                            {adminsData.length}
                                        </b>
                                    </h2>
                                    <h5>Admins</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Card
                                onClick={() => onSelectRole("subscriptions")}
                                className={`w-100 d-flex flex-row align-items-center ps-4 ${selectedUser === "subscriptions" ? "slctd-btn" : "dflt-Btn"}`}
                            >
                                <Image src={selectedUser === "subscriptions" ? subscriptions_e : subscriptions_d} width={"60px"} />
                                <Card.Body className="ps-4">
                                    <h2>
                                        <b>
                                            {allPrevSubscriptions ? allPrevSubscriptions.length : "00"}
                                        </b>
                                    </h2>
                                    <h5>Subscriptions</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={12} className="mt-4" style={{ margin: "0px auto" }}>
                            {selectedUser != "null" ? switchUsers(selectedUser) : ""}
                        </Col>
                    </Row>
                    {
                        selectedUser != "null" ? "" : <AllProducts />
                    }
                </Col>
            </Row>
        </section>
    );
}

export default UserDashboard;