import { useState } from "react";
import React from "react";
import BasicRegisterPage from "./BasicRegisterForm";

function RegisterPage(props) {
  const [loadForm, SetLoadForm] = useState(false);
  const loadTheForm = (userType) => {
    // console.log("I am loading the form");
    console.log("usertype is ", userType)
    SetLoadForm(true)
  }

  return (
    <div>
      <BasicRegisterPage onSubmitClicked={loadTheForm}  />
      {/* {loadForm ? <InstituteForm /> : <BasicRegisterPage onSubmitClicked={loadTheForm}  />} */}
    </div>
  );
}

export default RegisterPage;
