import React from "react";
import { Switch } from "@mui/material";
import { activeStyle, hoverStyle, basic } from '../../themes/basic';

const SwitchStyle = (props) => {
  return (
    <Switch
      sx={{
        marginRight: 1,
        width: 49,
        height: 16,
        padding: 0,

        
        "& .MuiSwitch-thumb": {
          width: 8,
          height: 8,
          boxShadow: 0,
          transform: "translateX(0px)", 
        },
        
        "& .MuiSwitch-track": {
          backgroundColor: basic.logoGreyColor,
          //backgroundColor: "transparent",
          border: "2px solid " + basic.logoGreyColor,
          //opacity: 0.6,
        },
        
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: basic.greenColor,
          //border: "2px solid #f3f3f3",
          //backgroundColor: "transparent",
          border: "2px solid "+ basic.greenColor,
          opacity: 1,
        },
        
        "& .MuiSwitch-switchBase": {
          //color: basic.redColor,
          color:"white",
         // opacity:0.6,

          "&.Mui-checked": {
            color: "white",
           //color:basic.greenColor,
            "& .MuiSwitch-thumb": {
              transform: "translateX(16px) !important", 
            },
          },
        },
      }}
      {...props}
    />
  );
};

export default SwitchStyle;
