import React, { useState, useEffect } from 'react';
import { Box, IconButton, FormControlLabel } from '@mui/material';
import PageHeading from '../page_heading';
import CustomStepper from '../common/custom_stepper';

const HeadingWithStep = ({title: aHeadingName,step: aCurrentStep}) => {

const _step_arrays = ["Products", "Variants", "Courses"];

return (
        <Box style={{
            display: "flex",
            justifyContent: "space-between", 
            alignItems: "left",             
            width: "100%",  
            marginBottom:"5px"                  
            }}>
            <Box>
                <PageHeading title={aHeadingName} style={{ marginBottom: "20px" }} />
            </Box>
            <Box sx={{ width: "30%", margin: "auto 0px auto auto", position:"relative", top:"10px" }}>
                <CustomStepper activeStep={aCurrentStep} steps={_step_arrays} />
            </Box>
        </Box>
)}

export default HeadingWithStep;