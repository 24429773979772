import React, { useEffect, useState } from 'react'
import {
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    TextField,
    Button,
    Grid,
    Box,
    FormControlLabel,
    Alert,
    IconButton
  } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Add as AddIcon, Edit as EditIcon, 
    Delete as DeleteIcon, Layers as CategoryIcon, 
    PersonAddAlt as AssignUserIcon, Clear as ClearAllIcon,
    Details as DetailsIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { basic, textFieldWithLabel } from '../../../themes/basic';
import LoadingOverlay from '../common/loading_overlay';
import DataGridStyle from '../data-table/datagrid_style';
import CustomButton from '../common/button_one';
import MessageSnackbar from '../common/message_snackbar';
import ModelPopup from "../common/modal_popup";
import PageHeading from '../page_heading';
import BackButton from '../common/back_button';
import SwitchStyle from '../SwitchStyle';

export default function SubscriptionDetails() {
    const { userId } = useParams(); 
    //console.log(userId);
    const __location = useLocation();
    const __navigate = useNavigate();
    const [__loading, __setLoading] = useState(false); 
    const [__userSubscriptionData, __setUserSubscriptionData] = useState([]);
    const [__userDetails, __setUserDetails] = useState([]);
    const [__customerAccountId, __setCustomerAccountId] = useState("");
    const [__editRowId, __setEditRowdId] = useState(null);
    const [__updatedRows, __setUpdatedRows] = useState([]);
    const [__startDate, __setStartDate] = useState(null);
    const [__endDate, __setEndDate] = useState(null);
    const [__tempEditedRow, __setTempEditedRow] = useState(null);

    const [__modalSaveOpen, __setModalSaveOpen] = useState(false);
    const [__descriptionMessage, __setDescriptionMessage] = useState(null);

    const [__deleteSubscriptionOpen, __setDeleteSubscriptionOpen] = useState(false);
    const [__deleteDescriptionMessage, __setDeleteDescriptionMessage] = useState(null);
    const [__deleteRowId, __setDeleteRowId] = useState(null);

    const [__snackbarOpen, __setSnackbarOpen] = useState(false);
    const [__snackbarMessage, __setSnackbarMessage] = useState("");
    const [__snackbarVariant, __setSnackbarVariant] = useState("success");

    
    const __getCustomerAccountUserList = async () =>{
        try {
            __setLoading(true);
            const _getCustomerUserData = await axios.get(`/subscription/getCustomerUserSubscription/${userId}`);
            __setLoading(false);
            __setUserDetails(_getCustomerUserData.data.data)
            const _productCourseData = _getCustomerUserData.data.data?.subscriptionData?.map((aItem) => ({
                id:aItem._id,
                userName:_getCustomerUserData.data.data.userName,
                courseName:aItem.courseId?.courseName,                
                productName:aItem.productId?.productName,
                variantName:aItem.variantId?.variantName,
                grade:aItem.usergrade,
                version:aItem.version,
                startDate: aItem.startDate,
                endDate: aItem.endDate,
                status: aItem.status,
                createAt:aItem.createdAt,
                updatedAt:aItem.updatedAt,
                isVisible:aItem.isVisible,
              }));

            __setUserSubscriptionData(_productCourseData);   
            __setUpdatedRows(_productCourseData);             
        }catch(error){
            console.error('Error:', error);
        }finally{
                __setLoading(false);
        }
    }

    useEffect(() => {
        const _getCurrentCustomerId = localStorage.getItem("_accountId");
        __setCustomerAccountId(_getCurrentCustomerId);
        __getCustomerAccountUserList();
    }, []);

    const __handleEditSubscription = (aRowId) => {
        __handleCancelEdit();
        const _rowToEdit = __userSubscriptionData.find(row => row?.id === aRowId);
        __setTempEditedRow({ ..._rowToEdit });
        __setEditRowdId(aRowId);
        
    }

    const __handleCancelEdit = () => {
        const _cancelToEdit = __userSubscriptionData.map(row => row.id === __editRowId ? __tempEditedRow : row);
        __setUserSubscriptionData(_cancelToEdit);
        __setEditRowdId(null);
    }

    const __handleChange = (aRowId, aField, aValue) => {
        console.log(aRowId,aField,aValue);
        const _convertFormat = __convertDateToISOFormat(aValue)
       __setUserSubscriptionData((preRows) =>
            preRows.map((row) =>
                row.id === aRowId ? { ...row, [aField]: _convertFormat } : row
            )
        );
    }

    const __handleSaveClick = () => {
        const _notifyMessage = "Are you sure you want to update the subscription?"
        __setDescriptionMessage(_notifyMessage);
        __setModalSaveOpen(true);
    }
    const __handleSaveModalClose = () => __setModalSaveOpen(false);

    const __handleSaveSubscription = async () => {
        __setModalSaveOpen(false);
        const editedRow = __userSubscriptionData.find(row => row.id === __editRowId);
        __setEditRowdId(null);
        //console.log(editedRow.startDate, editedRow.endDate);        
        __setLoading(true);
        const _updateSubscription = await axios.put(`/subscription/updateSubscription/${__editRowId}`, {
            startDate:editedRow.startDate,
            endDate:editedRow.endDate,
        });
        if(_updateSubscription.data.status =="success"){
            __setLoading(false);   
            __getCustomerAccountUserList();
            __triggerSnackbar("Successfully updated the subscription.","success"); 
        }else{
            __setLoading(false);   
            __triggerSnackbar("Failed to update the subscription.","error"); 
        }
    }

    const __handleDeleteSubscriptionClose = () => __setDeleteSubscriptionOpen(false);

    const __handleDeleteSubscriptionOpen = async (aRowId) => {
        console.log(aRowId);
        __setDeleteRowId(aRowId);
        const _notifyMessage = "Are you sure you want to delete the subscription?";
        __setDeleteDescriptionMessage(_notifyMessage);
        __setDeleteSubscriptionOpen(true);
    }

    const __handleDeleteSubscription = async () => {
        __setDeleteSubscriptionOpen(false);        
        __setLoading(true);
        const _deleteSubscription = await axios.delete(`/subscription/delete/${__deleteRowId}`);
        if(_deleteSubscription.data.status == "success"){
            __setLoading(false);   
            __getCustomerAccountUserList();
            __triggerSnackbar("Subscription deleted successfully.","success"); 
        }else{
            __setLoading(false);
            __triggerSnackbar("Unable to delete the subscription.","error"); 
        }
     }

    const __getTodayDate = () => {
        const _today = new Date();
        return _today.toISOString().split("T")[0];
    };

    const __getOneDayBeforeTodayDate = () => {
        const _today = new Date();
        const _beforeOneDay = new Date(_today);
        _beforeOneDay.setDate(_beforeOneDay.getDate() - 1);
        return _beforeOneDay.toISOString().split("T")[0];
    };

    const __calculateMinEndDate = (aStartDate) => {
        if (!aStartDate) return '';  

        const _dateParts = aStartDate.split('-'); 
        if (_dateParts.length === 3 && _dateParts[0].length === 2) {
          aStartDate = `${_dateParts[2]}-${_dateParts[1]}-${_dateParts[0]}`;
        }

        const _start = new Date(aStartDate);

        if (isNaN(_start.getTime())) {
            throw new Error('Invalid date format');
        }
        _start.setDate(_start.getDate() + 1); 
        return _start.toISOString().split("T")[0];  
    };

    const __backToSubscriptionPage = () => {
        __navigate("../subscription");
    }

    const __convertDateToISOFormat = (aDateString) => {
        if(!aDateString) return "";
        const [_day, _month, _year] = aDateString.split('-');      
        return `${_year}-${_month.padStart(2, '0')}-${_day.padStart(2, '0')}`;
    }

    const __triggerSnackbar = (message, variant) => {
        __setSnackbarMessage(message);
        __setSnackbarVariant(variant);
        __setSnackbarOpen(true);
    };

    const __handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        __setSnackbarOpen(false);
        __setModalSaveOpen(false);
    };
    

    if (__loading) {
        return <LoadingOverlay loading={__loading} />
    }
    return (
        <div>
            <Container fluid={true} style={{ padding:"0px"}}>
                
                
                <Grid container spacing={0}>
                    <Box sx={{ padding: "1px 0px" }}>
                        <Typography variant="h6" sx={{ fontFamily:basic.fontFamily, fontSize:basic.headingFontSize,color: basic.colorBlack}} component="div">
                            Username: <strong>{__userDetails.userName}</strong>
                        </Typography>
                    </Box>
                    <Grid item xs={12} sm={12}>
                        <Box
                        sx={{
                        height: "auto",
                        width: "100%",
                        ul: {
                        bgcolor: "#f6f6f6",
                        color: "#ffffff",
                        },
                        }}
                        >

                        <div style={{ display: "flex", position:"absolute", marginTop: 9,zIndex:"1000" }}>       
                            <BackButton backToPage="../subscription" aButtonText="Back" />
                        </div>
                        <DataGridStyle
                            rows={__userSubscriptionData}
                            columns={[
                            {
                                field: "userName",
                                headerName: "User Name",
                                hide:true,
                            },
                            {
                                field: "productName",
                                headerName: "Product Name",
                                flex: 1.2,
                                headerAlign: "left",                                
                            },
                            {
                                field: "variantName",
                                headerName: "Variant Name",
                                flex: 1.2,
                                headerAlign: "left",                                
                            },
                            {
                                field: "courseName",
                                headerName: "Course",
                                flex: 1,
                                headerAlign: "left",
                            },
                            {
                                field: "grade",
                                headerName: "Grade",
                                flex: 1,
                                headerAlign: "left",
                            },
                            {
                                field: "version",
                                headerName: "Version",
                                maxWidth:90,
                                headerAlign: "left",
                            },                            
                            {
                                field: "startDate",
                                headerName: "Start Date",
                                flex: 1,
                                headerAlign: "left",
                                renderCell: (params) => {
                                    const _todayDate = new Date();
                                    const _startDate = new Date(params.row.startDate.split('-').reverse().join('-')); // Convert DD-MM-YYYY to Date object
                                    const _isPastOrTodayDate = _startDate <= _todayDate; // Check if start date is in the past or today
                                    
                                    return __editRowId === params.row.id ? (
                                        <TextField
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { marginTop: "4px", fontSize: basic.labelFontSize, fontFamily: basic.fontFamily }
                                        }}
                                        sx={textFieldWithLabel}
                                        variant="outlined"
                                        size="small"
                                        style={{ marginRight: '10px' }}
                                        value={__convertDateToISOFormat(params.row.startDate)}
                                        onChange={(e) => __handleChange(params.row.id, 'startDate', e.target.value)}                                        
                                        inputProps={{
                                            min: __getTodayDate(),
                                            maxLength: 10,
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        disabled={_isPastOrTodayDate}
                                        />
                                    ): (
                                        <Typography variant="h6" sx={{ fontSize:basic.contentFontSize, fontFamily:basic.fontFamily, color: basic.colorBlack}}  component="span">
                                            {params.row.startDate}
                                        </Typography>
                                    )
                
                            }
                            },
                            {
                                field: "endDate",
                                headerName: "End Date",
                                flex: 1,
                                headerAlign: "left",
                                renderCell: (params) => (
                                    __editRowId === params.row.id ? (
                                        <TextField
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { marginTop: "4px", fontSize: basic.labelFontSize, fontFamily: basic.fontFamily }
                                        }}
                                        sx={textFieldWithLabel}
                                        variant="outlined"
                                        size="small"
                                        style={{ marginRight: '10px' }}
                                        value={__convertDateToISOFormat(params.row.endDate)}
                                        onChange={(e) => __handleChange(params.row.id, 'endDate', e.target.value)}
                                        inputProps={{
                                            min: __getOneDayBeforeTodayDate(),
                                            //min: __calculateMinEndDate(params.row.startDate),
                                            maxLength: 10,
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        />
                                    ) : (
                                        <Typography variant="h6" sx={{ fontFamily:basic.fontFamily,fontSize:basic.contentFontSize, color: basic.colorBlack}}  component="span">
                                            {params.row.endDate}
                                        </Typography>
                                    )
                                ),
                            },
                            {
                                field: "status",
                                headerName: "Status",
                                maxWidth:90,
                                headerAlign: "left",
                                renderCell: (params) => {
                                    return (
                                        <Typography variant="h6" sx={{ fontSize:basic.contentFontSize, fontFamily:basic.fontFamily, color: basic.colorBlack, textTransform:"capitalize"}}  component="span">
                                            {params.row.status}
                                        </Typography>
                                    )
                                }                            
                            },
                            {
                                field: "actions",
                                headerName: "Actions",
                                flex: 1,
                                maxWidth: 150,
                                headerAlign: "left",
                                disableExport: true,
                                renderCell: (params) => (
                                <Box>
                                    
                                    {__editRowId === params.row.id ? (
                                        <>
                                            <IconButton
                                                aria-label="Save"
                                                onClick={() => __handleSaveClick(params.row.id)}
                                            >
                                                <SaveIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Save' />
                                            </IconButton>
                                            <IconButton
                                                aria-label="Cancel"
                                                onClick={__handleCancelEdit}
                                            >
                                                <CancelIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Cancel' />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <IconButton
                                        disabled={params.row.status === "expired"}
                                        aria-label="Edit"
                                        onClick={() => __handleEditSubscription(params.row.id)}
                                        >
                                            <EditIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Edit' />
                                        </IconButton>
                                    )}                                    

                                    <IconButton
                                        //disabled={}
                                        aria-label="Delete"
                                        onClick={() => __handleDeleteSubscriptionOpen(params.row.id)}
                                        disabled={__editRowId === params.row.id || params.row.status === "expired" || params.row.status === "active"}                                  
                                        >
                                        <DeleteIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Delete' />
                                    </IconButton>
                                    
                                </Box>
                                ),
                            },
                            
                            ]}
                            disableSelectionOnClick
                            autoHeight={true}
                            checkboxSelection={false}
                            //selectionModel={__selectedProduct}
                            onSelectionModelChange={(selectedItems) => {
                                //selectedProductFunc(selectedItems)
                            }}
                           getRowHeight={(params) => {}}
                        />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            

            { /* save subscription popup */}
            <ModelPopup
                open={__modalSaveOpen}
                onClose={__handleSaveModalClose}
                onConfirm={__handleSaveSubscription}
                description={__descriptionMessage}
                title="Alert"    
                loading={__loading}
                icon={Alert}
                confirmText="Confirm"
                cancelText="Cancel"
            />

            { /* delete subscription popup */}
            <ModelPopup
                open={__deleteSubscriptionOpen}
                onClose={__handleDeleteSubscriptionClose}
                onConfirm={__handleDeleteSubscription}
                description={__deleteDescriptionMessage}
                title="Alert"    
                loading={__loading}
                icon={Alert}
                confirmText="Confirm"
                cancelText="Cancel"
            />

            { /* message snackbar */}
            <MessageSnackbar
                message={__snackbarMessage}
                variant={__snackbarVariant}
                open={__snackbarOpen}
                onClose={__handleSnackbarClose}
            />

        </div >
    );

}