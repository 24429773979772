import React from 'react';
import { StepLabel, Stepper, Step, } from '@mui/material';
import { basic } from '../../../themes/basic';


const CustomStepper = ({activeStep, steps}) => {
    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
                <Step
                key={label}
                sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                    color: basic.greenColor, // circle color (COMPLETED)
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                        color: basic.greenColor, // Just text label (COMPLETED)
                        fontWeight:"bold"
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                    color: basic.greenColor, // circle color (ACTIVE)
                    fontWeight:"bold",
                    },
                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                        color: basic.greenColor, // Just text label (ACTIVE)
                    },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "white", // circle's number (ACTIVE)
                    },
                    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                    marginTop: "8px",
                    fontSize:basic.contentFontSize,
                    fontFamily:basic.fontFamily
                    },

                    "& .MuiStep-root .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed .MuiStepConnector-line": {
                        borderColor: basic.greenColor,
                    },

                    "& .MuiStepConnector-line":{
                        borderColor: basic.stepNormalLine, // Default line color
                    },
                    "&.Mui-completed .MuiStepConnector-line":{
                        borderColor: basic.greenColor, // Default line color
                        fontWeight:"bold",
                    },
                    "&.Mui-active .MuiStepConnector-line":{
                        borderColor: basic.greenColor, // Default line color
                        fontWeight:"bold",
                    },
                    
                }}
                >
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default CustomStepper;