import React, { useState } from "react";
import { Button, Container, Grid, TextField } from "@mui/material";
import axios from "axios";
import bgImg from "../../components/Assets/authoring-bg.jpg"
import logo from "../../components/Assets/logo.png"

const ForgotPassword = () => {
    const [pin, setPin] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [pinError, setPinError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("")
    const [message, setMessage] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const handlePinChange = (e) => {
        // const value = e.target.value;
        // if (/^\d{0,6}$/.test(value)) {
        //     setPin(value);
        //     setPinError("");
        //     console.log("if");
        // } else {
        //     console.log("else");
        //     setPinError("PIN must contain only numbers");
        // }
        setPin(e.target.value)
    };
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (pin === "" || newPassword === "" || confirmPassword === "") {
            setErrorMessage("Please enter a value in all fields");
            return;
        }
        const value = pin
        if (/^\d{0,6}$/.test(value)) {
            setPin(value);
            setPinError("");
        } else {
            setPinError("PIN must contain only numbers and it cannot exceed 6 digits");
        }
        // Validate Passwords
        const passwordValue = newPassword.trim();
        const confirmPasswordValue = confirmPassword.trim();
        if (passwordValue !== confirmPasswordValue) {
            setPasswordError("Passwords do not match");
            setErrorMessage("");
            return;
        } else {
            setPasswordError("");
        }
        setErrorMessage("");

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userEmail = params.get("userEmail");

        let payload = {
            user_email: userEmail,
            pin: pin,
            new_password: newPassword
        }

        let platform = params.get("platform")
        let forgotPasswordForPlatform = platform;

        axios.post(`/${forgotPasswordForPlatform}/changePassword`, payload)
            .then((res) => {
                if (res.status === 200) {
                    setSuccessMessage(res.message);
                    setSubmitDisabled(true);
                }
                setMessage(res.data.message)
            }).catch((err) => {
                console.log("Error", err);
            })
        console.log("submitteddddddddddddddddddddddd");
    }

    return (
        <div
            style={{ position: "relative", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", background: `url(${bgImg})`, backgroundSize: "cover" }} >
            <Container maxWidth="xs" style={{ backgroundColor: "white", padding: "25px", minWidth: "368px", marginTop: "110px" }} >
                <img src={logo} style={{ position: "absolute", top: "100px", left: "50%", transform: "translateX(-50%)", zIndex: 1, width: "200px" }} />
                <div style={{ background: "white", textAlign: "center", marginBottom: "20px" }} >
                    <label style={{ fontWeight: "bold" }}>CHANGE PASSWORD</label>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField size="small" name="enter_pin" id="outlined-basic" label="Enter Pin" variant="outlined" value={pin} style={{ width: "100%", fontSize: "15px" }} onChange={handlePinChange} />
                        <div style={{ textAlign: "center", color: "red" }}>
                            <b>{pinError && <div className="error">{pinError}</div>}</b>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField size="small" name="enter_new_password" id="outlined-basic" type="password" value={newPassword} label="Enter New Password" variant="outlined" style={{ width: "100%" }} onChange={handleNewPasswordChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField size="small" name="confirm_new_password" id="outlined-basic" type="password" value={confirmPassword} label="Confirm Password" variant="outlined" style={{ width: "100%" }} onChange={handleConfirmPasswordChange} />
                        <div style={{ textAlign: "center", color: "red" }}>
                            <b>{passwordError && <div className="error">{passwordError}</div>}</b>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <button className="w-100" style={{ backgroundColor: "#8FBD56", borderColor: "#C2DF9F", color: "#fff", border: "none", minHeight: "40px", borderRadius: "5px", width: "100%", }} onClick={handleSubmit} disabled={submitDisabled}>
                            Submit
                        </button>

                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ textAlign: "center", color: "red" }}>
                            <b>{errorMessage && <div>{errorMessage}</div>}</b>
                        </div>
                        <div style={{ textAlign: "center", color: "green" }}>
                            <b>{successMessage && <div>{successMessage}</div>}</b>
                        </div>

                    </Grid>
                    <Grid style={{ textAlign: "center" }} item xs={12}>
                        <div className="w-100">
                            <h6 className="text-center" style={{ color: "green" }}><b>{message}</b></h6>
                        </div>
                        <div className="w-100 mt-3" style={{ textAlign: "center" }}>
                            {message && (
                                <>
                                    <p>
                                        Click <a href="/"> <b>here</b> </a> to login
                                    </p>
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
export default ForgotPassword;