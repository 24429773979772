import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Navbar, Row } from "react-bootstrap";
import NavBar from "../navBar";
import SchoolVerifications from "./SchoolVerifications";
import AdminDefaultPage from "./AdminDefaultPage";
import ManageQuizPage from "./ManageQuiz/ManageQuizPage";
import QuestionBankForm from "./QuizAdmin/QuestionBankForm";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
function AdminDashboard(props) {
  const navigate = useNavigate()
  const location = useLocation();
  const [selectedBtn, setSelectedBtn] = useState("profile");
  const [searchItems, setSearchItems] = useState([
    {
      school_name: "Shanthinikethan",
      school_code: "SAN",
      quizName: "T1",
      noOfStudents: 10,
      createdDate: "",
      startDate: "",
      endDate: ""
    },
    {
      school_name: "Bethasda",
      school_code: "BETH",
      quizName: "T1",
      noOfStudents: 15,
      createdDate: "",
      startDate: "",
      endDate: ""
    },
  ]);
  useEffect(() => {
    let path = location.pathname.split("/")[2];
    setSelectedBtn(path)
    // switch (path) {
    //   case "questionbankform":
    //     setsel
    //     break;
    //   case "AdminDefaultPage":
    //     break
    //   case "SchoolVerifications":
    //     break
    //   case "ManageQuizPage":
    //     break
    //   default:
    //     break;
    // }
    // if(location.pathname === ``)
    console.log("location", location.pathname.split("/")[2]);
  }, [location.pathname])
  // const onselect = (e) => {
  //   setSelectedBtn(e.target.value)
  // }


  useEffect(() => {
    let isAdminLogged = localStorage.getItem("isAdminLogged");
    console.log("isAdminLoggedisAdminLoggedisAdminLogged", isAdminLogged);
    if (!isAdminLogged || isAdminLogged === undefined) {
      navigate('/adminlogin')
    }
  }, [location])

  return (
    <Container fluid>
      <NavBar />
      <Row>
        <Col style={{ position: "relative", maxWidth: "260px" }}>
          <Row>
            <Col xs={12}>
              <div>
                <Navbar bg="light" expand="lg">
                  <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <div>
                        <Col
                          xs={12}
                          className="d-flex flex-column lm-main-dashboard"
                          style={{
                            backgroundColor: "#ECECEC", paddingLeft: "0px", maxWidth: "260px",
                          }}
                        >
                          <button value="profile" className={`dashboatdBtns ${selectedBtn === "AdminDefaultPage" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate('/admin-dashboard/AdminDefaultPage')
                            }}>
                            Profile
                          </button>
                          <button value="ManageQuiz" className={`dashboatdBtns ${selectedBtn === "ManageQuizPage" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate('/admin-dashboard/ManageQuizPage')
                            }}>
                            Manage Quiz
                          </button>
                          <button value="verifications" className={`dashboatdBtns ${selectedBtn === "SchoolVerifications" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate('/admin-dashboard/SchoolVerifications')
                            }}>
                            Verifications
                          </button>
                          <button
                            value="QuestionBankForm"
                            className={`dashboatdBtns ${selectedBtn === "questionbankform" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate('/admin-dashboard/questionbankform')
                            }}
                          >
                            Question Bank Form
                          </button>
                          <button
                            value="registerSchool"
                            className={`dashboatdBtns ${selectedBtn === "questionbankform" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate('/admin-dashboard/registerSchool')
                            }}
                          >
                            Register School
                          </button>
                          <button
                            value="registerSchool"
                            className={`dashboatdBtns ${selectedBtn === "questionbankform" ? "dashboatdBtnsActive" : ""}`}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate('/admin-dashboard/registerSchool')
                            }}
                          >
                            Licenses
                          </button>
                        </Col>
                      </div>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Outlet />
          {/* {selectedBtn === "profile" ? <AdminDefaultPage /> : ""}
          {selectedBtn === "verifications" ? <SchoolVerifications /> : ""}
          {selectedBtn === "ManageQuiz" ? <ManageQuizPage schoolData={searchItems} /> : ""}
          {selectedBtn === "QuestionBankForm" ? <QuestionBankForm /> : ""} */}
        </Col>
      </Row>
    </Container>
  );
}
export default AdminDashboard;