import { useEffect, useState } from "react";
import React from "react";
import logoImg from "../Assets/logo.png";
import axios from "axios";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import NavBar from "../navBar";
import SelectActionCmnt from "./SelectActionCmnt";
import { Col, Container, Row } from "react-bootstrap";
import { TextField } from "@mui/material";
//import ChangeAndUpdatePassword from "./PasswordCompnts/ChangePassword";
import PasswordModal from "../Admin/PasswordCompnts/change_password"

function AdminLogin(props) {
    const navigate = useNavigate();
    const [acntVerified, setAcntVerified] = useState(false);
    const [show, setshow] = useState(false);
    const [error, setError] = useState("")

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState("forgotPassword");

    const [inputField, setInputField] = useState({
        user_email: "",
        user_password: "",
    });

    // const [isUserValid, SetisUserValid] = useState(true)
    // const [loading, SetLoading] = useState(false)
    // const [errorTxt, SeterrorTxt] = useState("")

    const [usersData, SetUsersData] = useState([])


    const anim = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: { duration: 1000 },
    });


    //taking inputs from user and assigning to respective variable
    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        });
        setError("")
    };

    const handleOpenModal = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    //Admin login
    const onSubmitBtn = (e) => {
        e.preventDefault();
        let payload = {
            userEmail: inputField.user_email,
            userPassword: inputField.user_password
        }

        axios.post("/admin/login", payload)
            .then((res) => {
                console.log("res.data.isAminVerified", res.data.isAdminVerified);
                if (res.data.isAdminVerified) {
                    localStorage.setItem("isAdminLogged", "true");
                    localStorage.setItem("adminId", res.data.adminId);
                    navigate('/adminDashboard')
                }
                else {
                    setError("Incorrect Email/Password");
                    // setTimeout(() => {
                    //     setError("")
                    // }, 3000);
                }

            })
            .catch((err) => {
                setError("Please enter all fields");
                setTimeout(() => {
                    setError("")
                }, 2000);
            })
        // if (inputField.user_email === "admin" && inputField.user_password === "admin") {
        //     // navigate("/selectSchool");
        //     // setAcntVerified(true)
        //     navigate('/adminDashboard')
        //     localStorage.setItem("isAdminLogged", true);
        // }
        // else {
        //     setError("Incorrect Email/Password")
        //     setTimeout(() => {
        //         setError("")
        //     }, 2000);
        // }
        // axios.get("/admin/pendngVerfn")
        //     .then((res) => {
        //         SetUsersData(res.data)
        //         navigate("/admin-dashboard");
        //     })
    };

    return (
        <div>
            {/* <NavBar /> */}
            <animated.div style={anim} id="REGISTER_FORM">
                {/* {loading ? <LoadingModel /> : null} */}
                {
                    usersData != null ? usersData.map((a, key) => {
                        console.log("zzzzzzzzzzzzzz", a._id);
                    }) : console.log("ddddddddddd")

                }
                <Container style={{ maxWidth: "500px" }}>
                    <Row>
                        <Col xs={12} className="mt-3" >
                            <img src={logoImg} alt="logoImg" style={{ width: "150px", margin: "0px auto", display: "block" }} ></img>
                        </Col>
                        <Col xs={12} style={{ textAlign: "center" }} className="mt-3">
                            <label style={{ fontWeight: "bold" }}>Admin Login Here</label>
                        </Col>
                        <Col xs={12} className="mt-3" >
                            <TextField size="medium" name="user_email" id="outlined-basic" label="EMAIL" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                        </Col>
                        <Col xs={12} className="mt-3" >
                            <TextField size="medium" name="user_password" id="outlined-basic" type="password" label="PASSWORD" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                        </Col>
                        <Col xs={12} className="text-end">
                            <button className="txt-btn" style={{ color: "red", textDecoration: "underline" }} onClick={(e) => {
                                e.preventDefault()
                                handleOpenModal("forgotPassword");
                            }}>
                                Forgot Password
                            </button>
                        </Col>
                        {
                            acntVerified ? <div className="mt-3">
                                <Col xs={12}  >
                                    <SelectActionCmnt />
                                </Col>
                            </div> : <Col item xs={12} className="mt-3 text-center"  >
                                <button className="btn-latest" variant={"contained"} onClick={onSubmitBtn}> LOGIN </button>
                            </Col>
                        }
                        <Col>
                            <div className="text-center" style={{ color: "red" }}>
                                {error}
                            </div>
                        </Col>

                        <PasswordModal
                        show={showModal}
                        actionType={modalType}
                        onClose={handleCloseModal}
                        />
                        {/* }<ChangeAndUpdatePassword show={show} setShow={setshow} actionType={"updatePassword"} /> {*/}
                    </Row>
                </Container>
            </animated.div>
        </div>
    );
}

export default AdminLogin;
