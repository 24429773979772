import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'; // Correct import for CloseIcon
import { styled } from '@mui/material/styles';
import {basic} from '../../../themes/basic';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10000,
    backgroundColor: 'transparent',
    borderRadius: "0px",
    borderTopRightRadius: "5px",
    height: "39px",
    color: basic.colorWhite,
    '&:hover': {
      backgroundColor: basic.colorWhite,
      '& svg': {
        color: basic.colorBlack,
      }
    },
  }));

const CloseButton = ({ onClose }) => {
  return (
    <CustomIconButton onClick={onClose}>
      <CloseIcon style={{ fontSize: '20px' }} />
    </CustomIconButton>
  );
};

export default CloseButton;
