import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Checkbox, FormGroup, FormControlLabel, FormLabel } from '@mui/material';
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import axios from "axios";
import PrimaryTextBox from "../CmnCompnts/TextBox";
import placeJson from '../JSONfiles/Place.json';

function Step1_AddSchool(props) {

    const [inputField, setInputField] = useState({
        schoolName: "",
        schoolCode: "",
        schoolPhoneNum: "",
        adrs_l1: "",
        adrs_l2: "",
        adrs_city: "",
        adrs_pin_code: "",
    });

    const [slctdProducts, setSlctdProducts] = useState({
        quiz: "",
        kengine: ""
    });

    const [isTaraSlctd, setIsTaraSlctd] = useState(false);
    const [country, setcountry] = useState("Select Country");
    const [cityList, setcityList] = useState([]);
    const [slctCity, setSlctCity] = useState("Select City");
    const [stateList, setStateList] = useState([]);
    const [slctState, setSlctState] = useState("Select State");

    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {

    })

    const onSubmit = (e) => {
        e.preventDefault();
        let data = {
            schoolName: inputField.schoolName,
            schoolCode: inputField.schoolCode,
            schoolPhoneNum: inputField.schoolPhoneNum,
            address: inputField.adrs_l1 + " " + inputField.adrs_l2 + " " + inputField.adrs_city + " " + inputField.adrs_pin_code,
            slctdProducts: slctdProducts,
            sessionEmail: localStorage.getItem('userEmail'),
            schoolCodeForUsers: "KAR_BLR_SCHL_"
        }
        axios
            .post("/lmAccounts/addSchool", data)
            .then((res) => {
                console.log("eeeeeeeeeeee", data)
                localStorage.setItem("schoolAdded", "true");
                localStorage.setItem("schoolVerified", "false");
                // setisSchoolFormSubmitted(true);
                props.OnStepsComplete()
            })
            .catch((error) => console.log(error));
    }

    const onProductSelect = (e) => {
        // e.preventDefault();
        console.log("selected product", slctdProducts);
        if (e.target.value == "tara") {
            e.target.checked ? setIsTaraSlctd(true) : setIsTaraSlctd(false)
        }
        else {
            setSlctdProducts({
                ...slctdProducts,
                [e.target.value]: e.target.checked,
            });
        }
    }

    const onCountrySelect = (countryName) => {
        let tempDt = Object.values(placeJson.Countries).filter((dt, i) => {
            return dt.CountryName === countryName
        })
        setStateList(tempDt)
    }

    const onStateSelect = (stateName) => {
        let tempDt = Object.values(stateList[0].States).filter((dt, i) => {
            return dt.StateName === stateName
        })
        console.log("-----------", tempDt);
        setcityList(tempDt)
    }

    return (
        <Container>
            <Row>
                <Col md={7} style={{ margin: "0px auto", marginTop: "15px", backgroundColor: "white", borderRadius: "5px", padding: "20px" }}>
                    <Row>
                        <Col xs={12}>
                            <h4>
                                Please Enter Your School Details
                            </h4>
                        </Col>
                        <Col xs={12} className="mt-2">
                            {/* <TextField size="medium" name="schoolName" id="outlined-basic" label="School NAME" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} /> */}
                            <PrimaryTextBox name="schoolName" label="School NAME" onChange={onChangeTxt} />
                        </Col>
                        <Col xs={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                            {/* <TextField size="medium" name="schoolCode" id="outlined-basic" label="School CODE" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} /> */}
                            <PrimaryTextBox name="schoolCode" label="School CODE" onChange={onChangeTxt} />
                        </Col>
                        <Col xs={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                            {/* <TextField size="medium" name="schoolPhoneNum" id="outlined-basic" label="School PHONE NO. " variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} /> */}
                            <PrimaryTextBox name="schoolPhoneNum" label="School PHONE NO. " onChange={onChangeTxt} />
                        </Col>
                        <Col xs={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                            {/* <TextField size="medium" name="adrs_l1" id="outlined-basic" label="Address Line 1" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} /> */}
                            <PrimaryTextBox name="adrs_l1" label="Address Line 1" onChange={onChangeTxt} />
                        </Col>
                        <Col xs={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                            {/* <TextField size="medium" name="adrs_l2" id="outlined-basic" label="Address Line 2" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} /> */}
                            <PrimaryTextBox name="adrs_l2" label="Address Line 2" onChange={onChangeTxt} />
                        </Col>
                        {/* <Col xs={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                            <Row>
                                <Col>
                                    <PrimaryTextBox name="adrs_country" label="Country" onChange={onChangeTxt} />
                                </Col>
                                <Col>
                                    <PrimaryTextBox name="adrs_state" label="State" onChange={onChangeTxt} />
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                            <Row >
                                <Col>
                                    <PrimaryTextBox name="adrs_city" label="City" onChange={onChangeTxt} />
                                </Col>
                                <Col>
                                    <PrimaryTextBox name="adrs_pin_code" label="Pin Code" onChange={onChangeTxt} />
                                </Col>
                            </Row>
                        </Col> */}
                        <Col xs={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                            <Row >
                                <Col >
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{backgroundColor:"white", color:"black", border:"1px solid lightGray", width:"100%"}}>
                                            {country}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ maxHeight: "250px", overflowY: "scroll" }}>
                                            {
                                                placeJson.Countries.map((dt, key) => {
                                                    return <Dropdown.Item onClick={() => {
                                                        setcountry(dt.CountryName)
                                                        onCountrySelect(dt.CountryName)
                                                    }}>
                                                        {dt.CountryName}
                                                    </Dropdown.Item>
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{backgroundColor:"white", color:"black", border:"1px solid lightGray", width:"100%"}}>
                                            {slctState}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ maxHeight: "250px", overflowY: "scroll" }}>
                                            {
                                                stateList[0] != undefined ? stateList[0].States.map((dt, key) => {
                                                    return <Dropdown.Item onClick={() => {
                                                        setSlctState(dt.StateName)
                                                        onStateSelect(dt.StateName)
                                                    }}>
                                                        {dt.StateName}
                                                    </Dropdown.Item>
                                                }) : <Dropdown.Item>
                                                    {"Please Select Country"}
                                                </Dropdown.Item>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col >
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{backgroundColor:"white", color:"black", border:"1px solid lightGray", width:"100%"}}>
                                            {slctCity}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ maxHeight: "250px", overflowY: "scroll" }}>
                                            {
                                                cityList[0] != undefined ? cityList[0].Cities.map((dt, key) => {
                                                    return <Dropdown.Item onClick={() => {
                                                        setSlctCity(dt)
                                                    }}>
                                                        {dt}
                                                    </Dropdown.Item>
                                                }) : <Dropdown.Item>
                                                    {
                                                        console.log("Ssssssssssss", cityList[0])
                                                    }
                                                    {"Please Select State"}
                                                </Dropdown.Item>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>
                        <Col>

                        </Col>

                        <Col xs={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                            <Row>
                                <Col md={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                                    <h4>
                                        Products
                                    </h4>
                                </Col>
                                <Col md={12} style={{ margin: "0px auto", marginTop: "15px" }}>
                                    <FormGroup onChange={onProductSelect}>
                                        <FormControlLabel control={<Checkbox />} value="tara" label="Tara" />
                                        <div>
                                            {
                                                isTaraSlctd ? <div style={{ backgroundColor: "#f9f9f9", border: "1px solid #cacaca", borderRadius: "5px", padding: "12px" }}>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Checkbox />} value="plus" label="Plus" />
                                                        <FormControlLabel control={<Checkbox />} value="star" label="Star" />
                                                        <FormControlLabel control={<Checkbox />} value="champ" label="Champ" />
                                                        <FormControlLabel control={<Checkbox />} value="pro" label="Pro" />
                                                        <FormControlLabel control={<Checkbox />} value="ace" label="Ace" />
                                                        <FormControlLabel control={<Checkbox />} value="expert" label="Expert" />
                                                        <FormControlLabel control={<Checkbox />} value="master" label="Master" />
                                                    </FormGroup>
                                                </div> : ""
                                            }
                                        </div>
                                        <FormControlLabel control={<Checkbox />} value="quiz" label="Quiz" />
                                        <FormControlLabel control={<Checkbox />} value="kengine" label="Kengine" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "15px", textAlign: "center", justifyContent: "center" }}>
                        <Col lg={4} >
                            <Button variant={"contained"} style={{ backgroundColor: "#90BB41", borderRadius: "0px", minWidth: "200px" }} onClick={onSubmit}> SUBMIT </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <br />
        </Container>

        // <Grid container spacing={"10"} direction={"column"}  justifyContent={"center"} style={{marginTop:"15px"}}>
        //     <Grid item lg={5} xs={12} md={8}>

        //     </Grid>
        //     <Grid item lg={5} xs={12} md={8}>
        //         <TextField size="medium" name="schoolName" id="outlined-basic" label="School CODE" variant="outlined" style={{ width: "100%" }} />
        //     </Grid>
        //     <Grid item lg={5} xs={12} md={8}>
        //         <TextField size="medium" name="schoolName" id="outlined-basic" label="School NAME" variant="outlined" style={{ width: "100%" }} />
        //     </Grid>
        //     <Grid item lg={5} xs={12} md={8}>
        //         <TextField size="medium" name="schoolName" id="outlined-basic" label="School NAME" variant="outlined" style={{ width: "100%" }} />
        //     </Grid>
        //     <Grid item lg={5} xs={12} md={8}>
        //         <TextField size="medium" name="schoolName" id="outlined-basic" label="School NAME" variant="outlined" style={{ width: "100%" }} />
        //     </Grid>
        // </Grid>
    )
}

export default Step1_AddSchool;