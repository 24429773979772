import { useContext, createContext, useState, useEffect } from "react";
import React from "react";
import { Button, Container, Grid, TextField } from "@mui/material";
import logoImg from "../Assets/logo.png";
import axios from "axios";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import NavBar from "../navBar";
import { GoogleLogin } from 'react-google-login';
import googleImg from '../Assets/google.png'
import FBImg from '../Assets/fb.png'
import Dashboard from "../Dashboard/Dashboard";
import Loading from "../CmnCompnts/Loading";
import { UserContext } from "../Context/Reducer";

const clientId = "259086890035-2894evlv8gbi4tljda2vj6q8il1dog6n.apps.googleusercontent.com"


function LoginPage(props) {

    const navigate = useNavigate();
    const { state, dispatch } = useContext(UserContext)

    const [inputField, setInputField] = useState({
        user_name: "",
        userEmail: "",
        userPassword: "",
        user_type: "DEFAULT",
    });

    const [isUserValid, SetisUserValid] = useState(true)
    const [loading, SetLoading] = useState(false)
    const [errorTxt, SeterrorTxt] = useState("")


    const anim = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: { duration: 1000 },
    });

    const onSuccess = (res) => {
        console.log("Login Success Current user:", res.profileObj);
    }

    const onFailure = (res) => {
        console.log("Login Failed res:", res);
    }


    //taking inputs from user and assigning to respective variable
    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        });
    };

    //Login button 
    const onSubmitBtn = (e) => {
        e.preventDefault();
        SetLoading(true);
        let newUser = {
            userEmail: inputField.userEmail,
            userPassword: inputField.userPassword,
        };

        axios
            .post("/lmAccounts/login", newUser)
            .then((res) => {
                SetLoading(false)
                if (res.data.userEmailVerified == "Active") {
                    if (res.data.userVerified) {

                        dispatch({ type: "Set_UserName", payload: { userName: res.data.userName, userEmail: res.data.userEmail } })

                        if (res.data.userTypeVerified == "BB" || res.data.userTypeVerified == "BC") {
                            console.log("login data", res.data);
                            navigate("/dashboard/completeProfile")

                        }
                        else {
                            navigate("/dashboard/acnt_type");
                        }
                        // if (res.data.userTypeVerified == "BB" || res.data.userTypeVerified == "BC") {
                        //     console.log("login data", res.data);
                        //     navigate("/dashboard")
                        // }
                        // else {
                        //     navigate("/dashboard/acnt_type");
                        // }
                        // if(res.data.userCompleteProfile > 2){
                        //     navigate("/dashboard/user_dashboard")
                        // }
                        SetisUserValid(true)
                        localStorage.setItem('userEmail', inputField.userEmail);
                        localStorage.setItem('lmAccountId', res.data._id);
                    }
                    else {
                        SetisUserValid(false)
                        SeterrorTxt("Inorrect Email/Password, Please check ")
                    }
                }
                else {
                    SetisUserValid(false)
                    SeterrorTxt("Please Verify Your Account")
                }
                // if (res.data.userEmailVerified == "Active") {
                //     if (res.data.userVerified) {
                //         SetUserName("shashank")
                //         if (res.data.userTypeVerified == "BB" || res.data.userTypeVerified == "BC") {
                //             console.log("login data", res.data);
                //             //Inserting data to store
                //             const dt = {
                //                 user_name:res.data.user_name,
                //                 userEmail:res.data.userEmail,
                //                 schoolDetails:res.data.olDetails,
                //                 users_list:res.data.users_list
                //             }

                //             res.data.user_complete_Profile == "2" ? navigate("/dashboard") : navigate("/completeProfile")
                //         }
                //         else {
                //             navigate("/acnt_type");
                //         }
                //         SetisUserValid(true)
                //         localStorage.setItem('userEmail', inputField.userEmail);
                //     }
                //     else {
                //         SetisUserValid(false)
                //         SeterrorTxt("Inorrect Email/Password, Please check ")
                //     }
                // }
                // else {
                //     SetisUserValid(false)
                //     SeterrorTxt("Please Verify Your Account")
                // }
            })
            .catch((error) => console.log(error));
    };

    return (

        <div>
            {/* <NavBar /> */}
            <animated.div style={anim} id="REGISTER_FORM">
                {loading ? <Loading /> : null}
                <Container maxWidth={"xs"} >
                    <Grid container direction="column" rowSpacing={3} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
                        {/* <Grid item xs={12} alignItems="center" justifyContent="center">
                            <img src={logoImg} alt="logoImg" style={{ width: "150px", margin: "0px auto", display: "block" }} ></img>
                        </Grid> */}
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <label style={{ fontWeight: "600", fontSize: "1.5rem" }}>Login with Learning Matters</label>
                        </Grid>
                        <Grid item xs={12} alignItems="center" justifyContent="center">
                            <TextField size="medium" name="userEmail" id="outlined-basic" label="EMAIL" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                        </Grid>
                        <Grid item xs={12} alignItems="center" justifyContent="center">
                            <TextField size="medium" name="userPassword" id="outlined-basic" type="password" label="PASSWORD" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                        </Grid>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Button variant={"contained"} onClick={onSubmitBtn} style={{ width: "200px", height: "50px", fontWeight: "bold", fontSize: "1rem" }}> LOGIN </Button>
                        </Grid>
                        <Grid item xs={12} style={isUserValid ? { textAlign: "center", display: "none" } : { textAlign: "center", display: "block" }}>
                            {isUserValid ? <label style={{ fontWeight: "bold", color: "red" }}>{errorTxt} </label> :
                                <label style={{ fontWeight: "bold", color: "red" }}>
                                    {errorTxt}
                                </label>}
                        </Grid>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <label>Dont't have an account? <a href="/register">Register</a></label>
                        </Grid>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <label>Or Login Using</label>
                        </Grid>
                        <Grid item container spacing={3} alignItems="center" justifyContent="center">
                            <Grid item >
                                <img src={googleImg} width={"75"}></img>
                                {/* <GoogleLogin
                                    clientId={clientId}
                                    buttonText=""
                                    onSuccess={onSuccess}
                                /> */}
                            </Grid>
                            <Grid item >
                                <img src={FBImg} width={"50"}></img>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </animated.div>
        </div>
    );
}

export default LoginPage;
