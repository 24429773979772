import React from 'react';
import Modal from '@mui/material/Modal';
import { Button, Typography, List, ListItem, ListItemText} from '@mui/material';

const SubscriptionDuplicateUserList = ({ open, duplicates, onClose, onReselect }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <div style={{ padding: 20, backgroundColor: 'white', margin: '100px auto', width: '400px' }}>
                <Typography variant="h6">Duplicate Subscriptions Found</Typography>
                <List>
                    {duplicates.map((duplicate, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={`User: ${duplicate.userId}`}
                                secondary={`Course: ${duplicate.courseId} | Grade: ${duplicate.usergrade}`}
                            />
                        </ListItem>
                    ))}
                </List>
                <Button variant="contained" color="primary" onClick={onClose}>Confirm Changes</Button>
            </div>
        </Modal>
    );
};

export default SubscriptionDuplicateUserList;
