import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import useGlobalData from '../../../../Zustand/GlobalData';
import { useNavigate } from 'react-router-dom';
import PaginationCompnt from '../../../CmnCompnts/PaginationCompnt';
export default function SchoolLicenses() {
    const { userLicenses, purchasedMasterLicense } = useGlobalData();
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const findTotalAllocatedLicense = (purchsedLicenseDt) => {
        console.log("findTotalAllocatedLicense", userLicenses);
        let allocatedLicenseNum = 0;
        userLicenses.map((dt, i) => {
            if (dt._userId != "" && dt._licensePurchaseId === purchsedLicenseDt._id) {
                allocatedLicenseNum += 1;
            }
        })
        return allocatedLicenseNum;
    }

    const checkLicenseAvailable = (purchsedLicenseDt) => {
        let response = false;
        let allocatedLicenseNum = 0;
        userLicenses.map((dt, i) => {
            if (dt._userId != "" && dt._licensePurchaseId === purchsedLicenseDt._id) {
                allocatedLicenseNum += 1;
            }
        })
        if (allocatedLicenseNum >= purchsedLicenseDt.total_units) {
            response = true;
        }
        return response;
    }
    // Pagination logic for 10 rows in a page.
    const filteredUsers1 = purchasedMasterLicense;
    const rowsPerPage = 10; // Number of rows to display on the first page
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedUsers = filteredUsers1.slice(startIndex, endIndex);


    const findExpiryDate = (period, frequency, startDate) => {
        // period = inputField.liscencePeriod;
        // frequency = parseInt(inputField.liscenceFrequency);
        // startDate = inputField.start_date;

        const start = new Date(startDate);
        let expiry;

        if (period && frequency && startDate) {
            switch (period) {
                case 'Week':
                    expiry = new Date(start.getTime() + frequency * 7 * 24 * 60 * 60 * 1000);
                    break;
                case 'Day':
                    expiry = new Date(start.getTime() + frequency * 24 * 60 * 60 * 1000);
                    break;
                case 'Month':
                    expiry = new Date(start.getFullYear(), start.getMonth() + frequency, start.getDate());
                    break;
                case 'Year':
                    expiry = new Date(start.getTime());
                    expiry.setFullYear(expiry.getFullYear() + frequency);
                    break;
                default:
                    console.error('Invalid period value:', period);
                    break;
            }
        }

        if (expiry) {
            console.log("start.getDate()", expiry);
            expiry.setDate(expiry.getDate() - 1);
            const expiryDate = expiry.toISOString().slice(0, 10);
            console.log(expiryDate); // output: 2023-08-30
            return expiryDate;
        } else {
            console.error('Error calculating expiry date');
            return "No Date Found";
        }
    }

    return (
        <div>
            <Row>
                <Col xs={10} >
                    <div className='mt-4 text-center'>
                        <h4><b>School Licenses</b></h4>
                    </div>
                </Col>
                <Col xs={2}>
                    <div>
                        <button className='mt-3 btn-latest mt-2' onClick={(e) => {
                            e.preventDefault();
                            navigate('../licenses/createLicense')
                        }}>Create License</button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={11} className='mt-2 m-auto'>
                    <div>
                        {console.log("purchasedMasterLicense", purchasedMasterLicense)}
                        {
                            (purchasedMasterLicense != undefined && purchasedMasterLicense.length > 0) ? <Table bordered>
                                <thead>
                                    <tr>
                                        <td>License No.</td>
                                        <td>License ID</td>
                                        <td>Category</td>
                                        <td>License Type</td>
                                        <td>Total Licenses</td>
                                        <td>Allocated Licenses</td>
                                        <td>Start Date</td>
                                        <td>Expiry Date</td>
                                        <td>Status</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        displayedUsers.map((dt, i) => {
                                            let expiryDate = findExpiryDate(dt.period, dt.frequency, dt.licenseStartDate);
                                            let isLicenseExpired = false;
                                            const today = new Date();
                                            if (today > new Date(expiryDate)) {
                                                isLicenseExpired = true;
                                            }
                                            return <tr>
                                                <td>{i + 1}</td>
                                                <td>{dt._id}</td>
                                                <td>{dt.category}</td>
                                                <td>{dt.license_type}</td>
                                                <td>{dt.total_units}</td>
                                                <td>{findTotalAllocatedLicense(dt)}</td>
                                                <td>{dt.licenseStartDate}</td>
                                                <td>{expiryDate}</td>
                                                <td>{isLicenseExpired ? <label style={{ color: "red" }}>Expired</label> : <label style={{ color: "green" }}>Active</label>}</td>

                                                <td>
                                                    <button style={{ backgroundColor: "whiteSmoke", fontSize: "14px", border: "1px solid lightgrey" }} onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate('../licenses/assignStudentsToLicense', {
                                                            state: {
                                                                licenseData: dt,
                                                                allocatedLicenses: findTotalAllocatedLicense(dt)
                                                            }
                                                        })
                                                    }} disabled={isLicenseExpired}>Assign Users</button>
                                                    <button className='ms-2' style={{ backgroundColor: "whiteSmoke", fontSize: "14px", border: "1px solid lightgrey" }}>
                                                        More
                                                    </button>
                                                    {/* <button style={{ backgroundColor: "white", fontSize: "14px", border: "1px solid lightgrey" }}>Delete</button> */}
                                                    {/* <button style={{ backgroundColor: "white", fontSize: "14px", border: "1px solid lightgrey" }}>Edit</button> */}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table> : <div className='text-center mt-4'>
                                <h5>No License Found</h5>
                            </div>
                        }
                        {
                            <div>
                                <PaginationCompnt currentPage={currentPage} setCurrentPage={setCurrentPage} totalData={filteredUsers1.length} rowsPerPage={rowsPerPage} />
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </div>

    )
}